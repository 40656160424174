import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/services/authentication.service';

declare var $: any;

@Component({
  selector: 'app-modal-update-info',
  templateUrl: './modal-update-info.component.html',
  styleUrls: ['./modal-update-info.component.scss']
})
export class ModalUpdateInfoComponent implements OnInit {
  showAlertModal:boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    if(this.authService.showAlertModal$) this.show();
  }

  navigate(route: string) {
    this.router.navigate([`/${route}`]);
    this.hide();
  }

  public show(): void {
    $('#messageUpdateModal').modal('show');
  }

  public hide(): void {
    $('#messageUpdateModal').modal('hide');
  }
}
