import { Injectable } from '@angular/core';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';

const { DateTime } = require("luxon");

@Injectable()
export class DateUtilService {
  defaultMyDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd-mmm-yyyy',
    // editableDateField: false
  };

  myDatePickerOptionsEditableFalse: IMyDpOptions = {
    dateFormat: 'dd-mmm-yyyy',
    editableDateField: false
  };

  convertMyDpModelToNeutralTimeISOString(myDpModel: IMyDateModel): string | null {
    if (!myDpModel) {
      return null;
    }
    
    return new Date(Date.UTC(myDpModel.date.year, myDpModel.date.month - 1, myDpModel.date.day))
      .toISOString()
      .substring(0, 23);
  }

  convertMyDpModelToEndOfDayNeutralTimeISOString(myDpModel: IMyDateModel): string | null {
    if (!myDpModel) {
      return null;
    }

    // Remove trailing 'Z'
    return new Date(Date.UTC(myDpModel.date.year, myDpModel.date.month - 1, myDpModel.date.day, 23, 59, 59, 999))
      .toISOString()
      .substring(0, 23);
  }

  convertMyDpModelToYearMonthDay(myDpModel: IMyDateModel): string | null {
    if (!myDpModel) {
      return null;
    }

    let month = '' + myDpModel.date.month;
    let day = '' + myDpModel.date.day;
    let year = '' + myDpModel.date.year;

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }

    return [year, month, day].join('');
  }

  getMonthNames(): string[] {
    const userLang = navigator.language;
    const result = [];

    for (let i = 0; i < 12; i++) {
      let month = new Date(2017, i).toLocaleString(userLang, { month: 'long' });

      month = month[0].toUpperCase() + month.substr(1);
      result.push(month);
    }
    return result;
  }

  getStatementYears(): number[] {
    const currYear = new Date(Date.now()).getFullYear();
    const result = [];

    result.push(currYear - 1);
    result.push(currYear);

    return result;
  }

  getOrderYears(): number[] {
    const currYear = new Date(Date.now()).getFullYear();
    const result = [];

    result.push(currYear - 2);
    result.push(currYear - 1);
    result.push(currYear);

    return result;
  }

  validateYearAndMonth(options) {
    let seedDate = DateTime.fromObject({
      year: options.year,
      month: options.month,
      day: 1
    });
    if (seedDate.isValid) {
      return { success: true, seedDate: seedDate };
    }

    if (seedDate.invalidReason === "year out of range") {
      return { success: false, message: "Año inválido" };
    }

    if (seedDate.invalidReason === "month out of range") {
      return { success: false, message: "Mes inválido" };
    }
    return { success: false, message: "Fecha inválida" };
  }

  limitsOfMoth(date) {
    return {
      start: this.startOfMonth(date),
      end: this.endOfMonth(date)
    };
  }

  startOfMonth(date) {
    return date.startOf("month").toISO({ includeOffset: false });
  }

  endOfMonth(date) {
    return date
      .endOf("month")
      .set({ milliseconds: 0 })
      .toISO({ includeOffset: false });
  }

}
