import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import {delay, mergeMap} from 'rxjs/operators';
import {
    FullfillmentCategory,
    ItemRequestFullfillment,
    ItemsFullfillment,
    ItemByFilter,
    ItemKardex,
    kardexByFilter,
    ListMerchandise,
    MerchandiseByFilter,
    PurchaseOrdens,
    PurchaseOrdensItem,
    ResellerFileToUpload,
    newProdiverFullfillment,
    StoreFulfillment,
    StoreBySKU,
    newCategoryFullfillment,
    newTagFullfillment,
    FullfillmentTag,
    ItemFullfillment,
    Return,
    ReturnDetail
    ,getEmail,
    BulkUploadRequest,
    BulkUploadProgressStatus,
    BulkUploadProgress,
    kardexFilters,
    kardexPaginator,
    ItemsFullfillmentFilters,
    ItemsFullfillmentPaginator,
    PurchaseOrdersPaginator,
    PurchaseOrdersFilters

  } from 'src/app/core/models/models';
import { PurchaseOrden } from 'src/app/views-fullfillment/purchaseOrder-detail/newPurchaseOrder/purchaseOrden.model';
import { FileUtilService } from '../util/file-util.service';
import {HttpErrorResponse} from '@angular/common/http';
import { ResellerAlertBanner } from '../../models/store.model';
import { ResellerService } from './reseller.service';
@Injectable()
export class FullfillmentService {
  constructor(
    private apiService: ApiService,
    private fileUtil: FileUtilService,
  ) { }

  public orderWithOutSku = 0;
  // DESACTIVADO: Usado para banner de alertas
  // public orderWithOutSkuFirst: string | undefined;
  // public orderWithOutSkuSecond: string | undefined;
  private listAlertBanner: ResellerAlertBanner[] = []
  private alertsBanner = new BehaviorSubject<ResellerAlertBanner[]>([]);
  alertsBanner$ = this.alertsBanner.asObservable();


  getCategory(): Observable<FullfillmentCategory[]> {
    return this.apiService.get<FullfillmentCategory[]>('Fullfillment/getCategory');
  }

  setNewItem(item: ItemRequestFullfillment,adjunto: ResellerFileToUpload,_skuByStore:StoreBySKU[],component:string): Observable<any>
  {
    const params = {
      FullfillmentItemId: item.FullfillmentItemId,
      Description : item.Description,
      SKU : item.SKU,
      Name : item.Name,
      IsActive:true,
      UPC:item.UPC,
      PartNumber:item.PartNumber,
      Weigth:item.Weigth,
      Witdth:item.Witdth,
      Length:item.Length,
      Hight:item.Hight,
      FileName:(adjunto==undefined)?'':adjunto.name,
      FileType:(adjunto==undefined)?'':adjunto.fileType,
      File:(adjunto==undefined)?'':adjunto.file,
      FullfillmentStoreItem:_skuByStore,
      FullfillmentCategoryId:item.FullfillmentCategoryId,
      FullfillmentTagId:item.FullfillmentTagId,
      component
    }

    return this.apiService.post('Fullfillment/itemSave', params);
  }

  getItems(): Observable<ItemsFullfillment[]> {
    return this.apiService.get<ItemsFullfillment[]>('Fullfillment/getItem');
  }

  getPaginatedItems(filters: ItemsFullfillmentFilters) {
    return this.apiService.get<ItemsFullfillmentPaginator>('Fullfillment/item', filters);
  }

  checkSkuExists(sku: string) {
    return this.apiService.get<ItemsFullfillmentPaginator>('Fullfillment/checkSkuExists', { sku });
  }

  isItemByReseller(){
    let res=this.apiService.get<any>('Fullfillment/isItemByReseller');
    return res;
  }
  setIsActiveItem(fullfillmentItemId: any,event: any): Observable<any>
  {
    const params = {
      FullfillmentItemId:fullfillmentItemId,
      IsActive:event
    }
    return this.apiService.post('Fullfillment/updateIsActiveItem', params);
  }

  setUpdateItemName(fullfillmentItemId: any,name:any,itemDescription:any,item:any,adjunto: ResellerFileToUpload,_skuByStore:StoreBySKU[]): Observable<any>
  {
    const params = {
      FullfillmentItemId:fullfillmentItemId,
      Name : name,
      Description : itemDescription,
      UPC:item.UPC,
      PartNumber:item.PartNumber,
      Weigth:item.Weigth,
      Witdth:item.Witdth,
      Length:item.Length,
      Hight:item.Hight,
      FileName:(adjunto==undefined)?'':adjunto.name,
      FileType:(adjunto==undefined)?'':adjunto.fileType,
      File:(adjunto==undefined)?'':adjunto.file,
      FullfillmentStoreItem:_skuByStore,
      FullfillmentCategoryId:item.FullfillmentCategoryId,
      FullfillmentTagId:item.FullfillmentTagId,
      UnitOfMeasure:item.UnitOfMeasure
    }
    return this.apiService.post('Fullfillment/UpdateItem', params);
  }

  setUpdateItemFF(item:any, component:string): Observable<any>
  {
    const params = {
      FullfillmentItemId: item.FullfillmentItemId,
      SKU: item.SKU,
      Name: item.Name,
      Description : item.Description,
      UPC: item.UPC,
      PartNumber: item.PartNumber,
      Weigth: item.Weigth,
      Witdth: item.Witdth,
      Length: item.Length,
      Hight: item.Hight,
      FullfillmentCategoryId: item.FullfillmentCategoryId,
      FullfillmentTagId: item.FullfillmentTagId,
      component
    }
    return this.apiService.post('Fullfillment/UpdateItem', params);
  }

  getItemByFilter(data:any): Observable<ItemsFullfillment[]>{
    const param={
      startDate :data.StartDate,
      endDate : data.EndDate,
      name :data.Name,
      sku : data.SKU,
      status : data.Status,
      Description: data.Description,
    }
    return this.apiService.get<ItemsFullfillment[]>('Fullfillment/getItemsByFilter', param);
  }
  /**Kardex */
  getKardex(item:any): Observable<ItemKardex[]> {
    const param={
      itemId :item
    }
    return this.apiService.get<ItemKardex[]>('Fullfillment/getKardex',param);
  }

   /**
   * 
   * @description Add function 
   * @see PT-1755
   */
  getKardexList(resellerId: string, sku: string, filters?: kardexFilters): Observable<kardexPaginator> {
    const url = `Fullfillment/getKardex/${sku}/reseller/${resellerId}`;
    return this.apiService.get<kardexPaginator>(url, filters);
  }

  getKardexItems(item:any): Observable<ItemsFullfillment[]> {
    const param={
      itemId :item
    }
    return this.apiService.get<ItemsFullfillment[]>('Fullfillment/getKardexItem',param);
  }
  getKardexByFilter(data:kardexByFilter): Observable<ItemKardex[]>{
    const param={
      itemId :data.FullfillmentItemId,
      startDate :data.StartDate,
      endDate : data.EndDate,
      movement :data.Movement,
      inStatus: data.InStatus,
      outStatus: data.OutStatus,
    }
    return this.apiService.get<ItemKardex[]>('Fullfillment/getKardexByFilter', param);
  }

  /**Merchandice */
  getCategoryItems(fullfillmentCategoryId:any): Observable<ItemsFullfillment[]> {
    const params = {
      FullfillmentCategoryId : fullfillmentCategoryId
    }
    return this.apiService.get<ItemsFullfillment[]>('Fullfillment/getCategoryItem',params);
  }

  setNewMerchandise(fullfillmentItemId: any,quantity: number)
  {
    const params = {
      FullfillmentItemId:fullfillmentItemId,
      QuantityReseller: quantity
    }
    return this.apiService.post('Fullfillment/setNewMerchandise', params);
  }

  getItemByReseller() {
    return this.apiService.get('Fullfillment/getItemsCombo');
  }

  getProvidersByReseller(){
    return this.apiService.get('Fullfillment/getProvidersCombo');
  }

  getProvider():Observable<newProdiverFullfillment[]>{
    return this.apiService.get<newProdiverFullfillment[]>('Fullfillment/getProvider');
  }

  getUPCByReseller(){
    return this.apiService.get('Fullfillment/getUPCCombo');
  }

  getListMerchandise(): Observable<ListMerchandise[]> {
    return this.apiService.get<ListMerchandise[]>('Fullfillment/getMerchandise');
  }

  getMerchandiseByFilter(data:MerchandiseByFilter): Observable<ListMerchandise[]>{
    const param={
      startDate :data.StartDate,
      endDate : data.EndDate,
      name :data.Name,
      sku : data.SKU,
      statusByFilter: data.StatusByFilter,
      categoryByFilter: data.CategoryByFilter
    }
    return this.apiService.get<ListMerchandise[]>('Fullfillment/getMerchandiseByFilter', param);
  }

  savePurchaseOrder(data: PurchaseOrden){
    return this.apiService.post('Fullfillment/savePurchaseOrder', data);
  }

  getListPurchaseOrder(): Observable<PurchaseOrdens[]> {
    return this.apiService.get<PurchaseOrdens[]>('Fullfillment/getPurchaseOrder');

  }

  // TODO: ESTA SERIA LA NUEVA QUE SUSTITUYE A getListPurchaseOrder()
  getListPurchaseOrders(filters: PurchaseOrdersFilters) {
    return this.apiService.get<PurchaseOrdersPaginator>('Fullfillment/getListPurchaseOrders', filters);
  }

  saveProvider(dataPro: newProdiverFullfillment){
    const data = {
      name:dataPro.Name,
      rfc: dataPro.RFC,
      contactName: dataPro.ContactName,
      phone: dataPro.Phone,
      email: dataPro.Emaill,
      address: dataPro.Address,
      suburb:dataPro.Suburb,
      isActive:dataPro.IsActive
    }
    return this.apiService.post('Fullfillment/saveProvider', data);
  }
  updateProvider(dataPro: newProdiverFullfillment){
    const data = {
      fullfillmentProviderId:dataPro.FullfillmentProviderId,
      name:dataPro.Name,
      rfc: dataPro.RFC,
      contactName: dataPro.ContactName,
      phone: dataPro.Phone,
      email: dataPro.Emaill,
      address: dataPro.Address,
      suburb:dataPro.Suburb,
      isActive:dataPro.IsActive
    }
    return this.apiService.post('Fullfillment/updateProvider', data);
  }
  getPurchaseOrderItem(fullfillmentPurchaseOrderId:any): Observable<PurchaseOrdensItem[]> {
    const param = {
      PurchaseOrderId:fullfillmentPurchaseOrderId
    }
    return this.apiService.get<PurchaseOrdensItem[]>('Fullfillment/getPurchaseOrderItem',param);
  }

  cancelPurchaseOrder(purchaseOrderId: any): Observable<any> {
    const data = {
      PurchaseOrderId: purchaseOrderId
    }
    return this.apiService.post('Fullfillment/cancelPurchaseOrder', data);
  }

  setUpdateItemPO(item: any,purchaseOrderId:any,FormatDate:any): Observable<any>
  {
    const params = {
      PurchaseOrderId:purchaseOrderId,
      DeliveryDate:FormatDate,
      Item:item

    }

    return this.apiService.post('Fullfillment/updatePurchaseOrder', params);
  }
  getStore():Observable<StoreFulfillment[]>{
    return this.apiService.get<StoreFulfillment[]>('Fullfillment/getStore');
  }

  getSKYByStore(item:any):Observable<StoreBySKU[]>{
    const params = {
      FullfillmentItemId:item
    }
    return this.apiService.get<StoreBySKU[]>('Fullfillment/getSKUByStore', params);
  }

  setDeleteValidateSKU(_FullfillmentStoreItemId: string,_FullfillmentItemId:any,sku:any,_StoreId:any): Observable<any>
  {
    const data = {
      FullfillmentStoreItemId:_FullfillmentStoreItemId,
      FullfillmentItemId:_FullfillmentItemId,
      SKU:sku,
      StoreId:_StoreId
    }

    return this.apiService.post('Fullfillment/deleteSkuByStore', data);
  }

  saveCategory(dataCat: newCategoryFullfillment){
    const data = {
      name:dataCat.Name,
      isActive:dataCat.IsActive
    }
    return this.apiService.post('Fullfillment/saveCategory', data);
  }
  updateCategory(dataCat: newCategoryFullfillment){
    const data = {
      fullfillmentCategoryId:dataCat.FullfillmentCategoryId,
      name:dataCat.Name,
      isActive:dataCat.IsActive
    }
    return this.apiService.post('Fullfillment/updateCategory', data);
  }

  getTag(): Observable<FullfillmentTag[]> {
    return this.apiService.get<FullfillmentTag[]>('Fullfillment/getTag');
  }

  saveTag(dataCat: newTagFullfillment){
    const data = {
      name:dataCat.Name,
      isActive:dataCat.IsActive
    }
    return this.apiService.post('Fullfillment/saveTag', data);
  }
  updateTag(dataCat: newTagFullfillment){
    const data = {
      fullfillmentTagId:dataCat.FullfillmentTagId,
      name:dataCat.Name,
      isActive:dataCat.IsActive
    }
    return this.apiService.post('Fullfillment/updateTag', data);
  }


  saveNewItem(item: ItemFullfillment): Observable<any>
  {
    const params = {
      FullfillmentItemId:0,
      Description :'',
      SKU : item.SKU,
      Name : item.Name,
      IsActive:true,
      UPC:'',
      PartNumber:'',
      Weigth:0,
      Witdth:0,
      Length:0,
      Hight:0,
      FileName:'',
      FileType:'',
      File:'',
      FullfillmentStoreItem:'',
      FullfillmentCategoryId:item.FullfillmentCategoryId,
      FullfillmentTagId:item.FullfillmentTagId,
      ProductId:item.ProductId,
      FullfillmentOrderItemId:item.FullfillmentOrderItemId,
      StoreId:item.StoreId,
      VariantId:item.VariantId,
      ResellerStoreId:item.ResellerStoreId,
      InventoryId:item.InventoryId
    }

    return this.apiService.post('Fullfillment/itemSaveSKU', params);
  }

  saveUpdateItem(_FullfillmentItemId:any,_ProductId:any,_StoreId:any,_SKU,_variantId:string, _resellerStoreId:string,_orderItem:string, _inventoryId:string): Observable<any>
  {
    const params = {
      FullfillmentItemId:_FullfillmentItemId,
      ProductId:_ProductId,
      StoreId:_StoreId,
      SKU:_SKU,
      VariantId:_variantId,
      ResellerStoreId:_resellerStoreId,
      FullfillmentOrderItemId:_orderItem,
      InventoryId: _inventoryId
    }

    return this.apiService.post('Fullfillment/itemSetSKU', params);
  }

  getReturn(): Observable<Return[]> {
    return this.apiService.get<Return[]>('Fullfillment/getReturn');
  }

  getReturnDetail(_FullfillmentOrderReturnId:any): Observable<ReturnDetail> {
    const params = {
      FullfillmentOrderReturnId:_FullfillmentOrderReturnId
    }
    return this.apiService.get<ReturnDetail>('Fullfillment/getReturnDetail', params);
  }

  saveReturn(_item: any, _orderId: any, _shipmentId: any): Observable<any> {
    const params = {
      items: _item,
      orderid: _orderId,
      shipmentId: _shipmentId
    };

    return this.apiService.post('Fullfillment/saveReturn', params);
  }

  sendEmailReturn(_returnId:any,_email:any): Observable<any>
  {
    const params = {
      email:_email,
      returnId:_returnId
    }
    return this.apiService.post('Fullfillment/sendEmailReturn', params);
  }

  getEmail(_OrderId:string): Observable<getEmail> {
    const params = {
      OrderId:_OrderId
    }
   let data= this.apiService.get<getEmail>('Fullfillment/getEmail', params);
   return data;
  }

  printReturnRMA(IdReturn: string) {
    return this.apiService.get<any>('Fullfillment/printReturnRMA',{ returnId: IdReturn})
      .pipe(
        mergeMap( returnPDF  =>
          this.fileUtil.SaveBase64PDFNewFile(IdReturn + '.pdf', returnPDF)
        )
      );
  }

  printReturnLabel(shipmentId: string) {
    return this.apiService.get<any>('Fullfillment/printReturnLabel',{ ShipmentId: shipmentId})
      .pipe(
        mergeMap( returnPDF  =>
          this.fileUtil.SaveBase64PDFNewFile(shipmentId + '.pdf', returnPDF)
        )
      );
  }

  getCountOrderWithoutSKU(): Observable<any> {
    let result =  this.apiService.get<any>('Orders/orderWithourSKU');
    return result;
  }

  dowloadRequestPDF(Folio): Observable<any>  {
    const param = {
      folio: Folio
    }
    let result =  this.apiService.get<any>('Fullfillment/dowloadRequestPDF', param);
    return result;
  }

  getPurchaseDateInstructions(): Observable<any> {
    return this.apiService.get<any>('Fullfillment/purchaseDateInstructions');
  }

  saveItemsMasive(items:any): Observable<any> {
    const params = {
        dataItem:items
      }
    return this.apiService.post<any>('Fullfillment/saveItemsMasive', params);
  }

  savePurchasesMassive(items: any): Observable<any> {
    const params = {
      dataItem: items
    };
    return this.apiService.post<any>('Fullfillment/savePurchasesMasive/batch', params);
  }

  postOrderAssortment(orderId: string): Observable<any> {
    return this.apiService.post<any>(`Fullfillment/OrderAssortment?orderId=${orderId}`);
  }

  getStoreDisconnected(): Observable<any> {
    return this.apiService.get<any>('Stores/Disconnected');
  }


  addAlertsBanner(alertsBanner: ResellerAlertBanner[]){
    this.listAlertBanner = [,...alertsBanner]
    this.alertsBanner.next(this.listAlertBanner)
  }

  postCancelOrder(orderId: string): Observable<any> {
    return this.apiService.post<any>(`Fullfillment/CancelOrder?orderId=${orderId}`);
  }

  postCancelOrderItem(orderItemId: string,OrderId: string): Observable<any> {
    return this.apiService.post<any>(`Fullfillment/CancelOrderItem?orderItemId=${orderItemId}&orderId=${OrderId}`);
  }

  clearDataAlertsBanner(){
    this.listAlertBanner = [];
    this.alertsBanner.next(this.listAlertBanner);
  }

  retrieveItemStore() {
    return this.apiService.get('Fullfillment/retrieveItemStore');
  }

  getItemBackOrderRequest(fullfillmentItemId: string): Observable<any> {
    const pathUrl = `Fullfillment/itemBackOrders/${fullfillmentItemId}`;
    return this.apiService.get(pathUrl);
  }

  getItemAssignedOrders(fullfillmentItemId: string): Observable<any> {
    const pathUrl = `Fullfillment/item-assigned-in-orders/${fullfillmentItemId}`;
    return this.apiService.get(pathUrl);
  }

  getOrdersToReallocateStock(fullfillmentItemId: string): Observable<any> {
    const pathUrl = `Fullfillment/item/reallocate-stock/${fullfillmentItemId}`;
    return this.apiService.get(pathUrl);
  }

  reallocateOrderItemsStock(fullfillmentItemId, orders) {
    const url = `Fullfillment/item/reallocate-stock/${fullfillmentItemId}`;
    return this.apiService.post(url, orders);
  }

  getDetails(resellerId: string) {
    const url = `Orders/bulk-uploads/reseller/${resellerId}`;
    return this.apiService.get<BulkUploadProgress[]>(url);
  }

  updateToSeenBulkUpload(resellerId: string, webhookProcessId: string): Observable<BulkUploadProgress[]> {
  const url = `Orders/bulk-upload/update-to-seen`;
  return this.apiService.put(url, { webhookProcessId, resellerId });
}

  bulkLoadOrdersRequest(bodyRequest: BulkUploadRequest) {
    const url = `Orders/bulk-load`;
    return this.apiService.post(url, bodyRequest);
  }

  downloadBulkUploadErrorDetails(bulkUploadHistoryId: string) {
    const url = `orders/bulk-upload/${bulkUploadHistoryId}/download`;
    return this.apiService.getF360(url, { responseType: 'blob' });
  }

  getOrderHierarchy(orderId): Observable<any> {
    const url = `orders/getOrderHierarchyFull/${orderId}`;
    return this.apiService.get(url);
  }

  getAddReturnModelData(orderId: string): Observable<any> {
    const url = `orders/getAddReturnModelData/${orderId}`;
    return this.apiService.get(url);
  }

  getAddressByOrder(orderId: string): Observable<any> {
    const url = `orders/address/${orderId}`;
    return this.apiService.get(url);
  }

  getOrderById(orderId: string): Observable<any> {
    const url = `orders/detailInfo/${orderId}`;
    return this.apiService.get(url);
  }

  markSeeBannerShp(resellerStoreId:string) {
    return of(true);
  }

  downloadKardexByItem(sku: string, resellerId: string): Observable<any> {
    const url = `Fullfillment/getKardex/${sku}/reseller/${resellerId}/download`;
    return this.apiService.get(url, { responseType: 'blob' });
  }
}
