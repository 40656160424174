import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-modal-update-tax-info',
  templateUrl: './modal-update-tax-info.component.html',
  styleUrls: ['./modal-update-tax-info.component.scss']
})
export class ModalUpdateTaxInfoComponent implements OnInit {
  showModal:boolean;
  isFullfillment:boolean;
  $onDestroy: Subject<void> = new Subject<void>(); 
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.showModal$.subscribe(state => {
      this.showModal = state;
    });

    if(this.showModal) this.show();
  }

  navigate(route: string) {
    
    this.authService.user$.pipe(takeUntil(this.$onDestroy)).subscribe(async (userState) => {
      this.isFullfillment = userState.isFullfillment;
    });

    if (this.isFullfillment) {
      this.router.navigate([`/fulfillment/perfil`]);
      this.hide();
    }
    else {
      this.router.navigate([`/${route}`]);
      this.hide();
    }
  }

  public show(): void {
    $('#messageTaxUpdateModal').modal('show');
  }

  public hide(): void {
    $('#messageTaxUpdateModal').modal('hide');
    this.authService.setShowModalState(false);
  }
}
