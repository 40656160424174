import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal.component';
import { ModalXLComponent } from './components/modal-xl-component';
import { ModalNotifyComponent } from './components/modal-notify.component';
import { ModalOnboardingComponent } from './components/modals/modal-onboarding/modal-onboarding.component';
import { ModalUpdateInfoComponent } from './components/modals/modal-update-info/modal-update-info.component';
import {FormsModule} from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    ModalComponent,
    ModalXLComponent,
    ModalNotifyComponent,
    ModalOnboardingComponent,
    ModalUpdateInfoComponent,
  ],
  exports: [
    ModalComponent,
    ModalXLComponent,
    ModalNotifyComponent,
    ModalOnboardingComponent,
    ModalUpdateInfoComponent,
  ]
})
export class ModalModule { }
