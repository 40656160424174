import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  PakkeServiceConfig,
  User,
  ShipmentTicket,
  ShipmentTicketEntry,
  ResellerDetail,
  AccountMovement,
  ShipmentItem,
  ShipmentHistory,
  ShipmentResume,
  Notification,
  GuiaBase64,
  Reseller,
  ResellerSearchFilter,
  ResellerBalanceFilterOptions,
  PagedResult,
  ResellerBalanceBreakdown,
  ReportHistory
} from 'src/app/core/models/models';

import { ApiService } from 'src/app/core/services/api.service';
import { FileUtilService } from 'src/app/core/services/util/file-util.service';
import { ShipmentOverweightTransaction } from 'src/app/views-helpdesk/shipments/model/shipment-overweight-transaction';
import { ExtraChargeShipmentTransaction } from 'src/app/views-helpdesk/shipments/model/shipment-chargeshipment-transaction';

@Injectable()
export class AdminService {
  constructor(
    private apiService: ApiService,
    private fileUtil: FileUtilService
  ) { }

  apiUrl = environment.apiUrl;

  // Service Configs

  getServiceConfigs(): Observable<PakkeServiceConfig[]> {
    return this.apiService.get<PakkeServiceConfig[]>('PakkeServiceConfigs');
  }

  postServiceConfig(serviceConfig: PakkeServiceConfig): Observable<any> {
    return this.apiService.post('PakkeServiceConfigs/', serviceConfig);
  }

  patchServiceConfig(serviceConfig: PakkeServiceConfig): Observable<any> {
    return this.apiService.patch('PakkeServiceConfigs/' + serviceConfig.PakkeServiceConfigId, serviceConfig);
  }

  deleteServiceConfig(body: PakkeServiceConfig): Observable<any> {
    return this.apiService.delete('PakkeServiceConfigs/' + body.PakkeServiceConfigId);
  }

  getHelpDeskUsers(): Observable<User[]> {
    return this.apiService.get<User[]>('Management/users?roleId=HELP_DESK');
  }

  getResellerUsers(): Observable<ResellerDetail[]> {
    return this.apiService.get<ResellerDetail[]>('Management/resellers')
      .pipe(map(res => res ? res.sort((a, b) => a.Email > b.Email ? 1 : -1) : []));
  }

  /* Tickets */

  getShipmentTickets(shipmentId: string): Observable<ShipmentTicket[]> {
    return this.apiService.get<ShipmentTicket[]>('Management/shipments/' + shipmentId + '/tickets');
  }

  getShipmentTicket(shipmentId: string): Observable<ShipmentTicket> {
    return this.apiService.get<ShipmentTicket[]>('Management/shipments/' + shipmentId + '/tickets')
      .pipe(map(res => res && res.length ? res[0] : null));
  }

  /** REFACTOR NEEDED: The helpdesk agent assigment and shipment tickets feature is not well designed.
   * Currently, only one ticket can be added to a shipment. If you add more, some things will not work properly.
  I recommend gettting rid of this feature and use a 3rd party product like Zendesk */
  createShipmentTicket(shipmentId): Observable<ShipmentTicket> {
    const shipmentTicket = {
      ShipmentId: shipmentId,
      Active: true
    };
    return this.apiService.post<ShipmentTicket>('Management/shipments/' + shipmentId + '/tickets', shipmentTicket);
  }

  getShipmentComments(ticketId: string): Observable<ShipmentTicketEntry[]> {
    return this.apiService.get<ShipmentTicketEntry[]>('Management/tickets/' + ticketId + '/entries');
  }

  /**
  * Currently, comments are being added to the first ShimpmentTicket record belonging to the Shipment table.
  * I recommend deleting the ShipmentTicket table and linking comments directly to the Shipment table.
  */
  createShipmentComment(ticketId: string, comments): Observable<ShipmentTicketEntry> {
    const shipmentComment = {
      ShipmentTicketId: ticketId,
      Comments: comments
    }
    return this.apiService.post<ShipmentTicketEntry>('Management/tickets/' + ticketId + '/entries', shipmentComment);
  }

  patchShipmentTicketAssignee(ticketId: string, assigneeId: string): Observable<any> {
    return this.apiService.patch('Management/tickets/' + ticketId + '/assign?assigneeId=' + assigneeId);
  }

  /* Resellers */
  getResellerDetail(resellerId: string): Observable<ResellerDetail> {
    return this.apiService.get<ResellerDetail>('Management/resellers/' + resellerId);
  }

  updateResellerReferr(updateReferr: any): Observable<ResellerDetail> {
    return this.apiService.patch<ResellerDetail>('Management/resellers/updateResellerReferr?resellerId='+updateReferr.resellerId + "&referralOwner=" +updateReferr.referralOwner + "&referralOwnerType=" +updateReferr.referralOwnerType+ "&referral=" +updateReferr.referral+ "&referralType="+updateReferr.referralType);
  }

  getResellerBalanceBreakdown(resellerId: string): Observable<ResellerBalanceBreakdown> {
    let Result : Observable<ResellerBalanceBreakdown>;
    Result = this.apiService.get<ResellerBalanceBreakdown>('Management/resellers/balance_backoffice', { resellerId_consult: resellerId});
    return Result;
    // return this.apiService.get<ResellerBalanceBreakdown>('Reseller/balance_backoffice/'+resellerId);
  }


  getResellerAccountStatement(resellerId: string, year: number, month: number, search: string): Observable<AccountMovement[]> {
    return this.apiService.get<AccountMovement[]>(`Management/resellers/${resellerId}/accountStatement/${year}/${month}?search=${search}`)
      .pipe(map(res => res ? res : []));

      // return this.apiService.get<AccountMovement[]>(`Management/resellers/${resellerId}/accountStatement?search=${search}`)
      // .pipe(map(res => res ? res : []));  
  }

  getResellersByFilter(filter: ResellerSearchFilter): Observable<PagedResult<Reseller>> {
    const { balanceState, ...compatibleApiFilters } = filter;

    // API expects a boolean property named hasNegativeBalance
    const searchCriteria = Object.assign(
      {
        hasNegativeBalance: balanceState === ResellerBalanceFilterOptions.All
          ? null
          : balanceState === ResellerBalanceFilterOptions.Negative
      },
      compatibleApiFilters
    );

    return this.apiService.get<any>('Management/resellers/countByFilter', searchCriteria)
      .pipe(
        mergeMap(countRes => {
          const records$ = countRes.counter > 0
            ? this.apiService.get<Reseller[]>('Management/resellers/byFilter', searchCriteria)
            : of([]);

          return records$.pipe(
            map(records => {
              return {
                records: records,
                totalItems: countRes.counter
              };
            })
          );
        })
      );
  }

    /* Shipments */
    getShipmentsByFilterBO(filter): Observable<any[]> {
      const result =  this.apiService.get<any[]>('Management/shipments/byFilterV2', filter);
      return result;
    }

  /* Shipments */
  getShipmentsByFilter(filter): Observable<PagedResult<ShipmentResume>> {
    return this.apiService.get<any>('Management/shipments/countByFilter', filter)
      .pipe(
        mergeMap(countRes => {
          const records$ = countRes.counter > 0
            ? this.apiService.get<ShipmentResume[]>('Management/shipments/byFilter', filter)
            : of([]);

          return records$.pipe(
            map(records => {
              return {
                records: records.map(i => {
                  i.CreatedAt = new Date(i.CreatedAt);
                  i.ExpiresAt = new Date(i.ExpiresAt);

                  return Object.assign(new ShipmentResume(), i);
                }),
                totalItems: countRes.counter
              };
            })
          );
        })
      );
  }

  /* Users */
  getUsersByFilter(filter): Observable<PagedResult<any>> {
    return this.apiService.get<any>('Management/users/countByFilter', filter)
      .pipe(
        mergeMap(countRes => {
          const records$ = countRes.counter > 0
            ? this.apiService.get<any[]>('Management/users/byFilter', filter)
            : of([]);

          return records$.pipe(
            map(records => {
              return {
                records: records.map(i => {
                  i.FechaRegistro = new Date(i.FechaRegistro);
                  i.Date = new Date(i.FechaRegistro).toLocaleDateString();

                  return i;
                }),
                totalItems: countRes.counter
              };
            })
          );
        })
      );
  }

  /* Users */
  getAllUsers(): Observable<any> {
    return this.apiService.get<any>('Management/users/all');
  }

  /* Promociones */
  getReportAllBonus(): Observable<any> {
    return this.apiService.get<any>('Management/serviceBonus/all');
  }

  filterReportBonus(filter): Observable<any[]> {
    const result =  this.apiService.get<any[]>('Management/serviceBonus/byFilter', filter);
    return result;
  }

  /* Transaction reports */
  getAllTransactionReport(): Observable<any> {
      return this.apiService.get<any>('Management/transactionReport/all');
  }

  /** Download transaction report*/
  downloadTransactionReport( key: string){
    return this.apiService.get<ReportHistory>('Management/transactions/' + key + '/report')
  }

  getShipmentById(id: string): Observable<ShipmentItem> {
    return this.apiService.get<ShipmentItem>('Management/shipments/' + id)
      .pipe(
        map(data => {
          data.CreatedAt = new Date(data.CreatedAt);
          data.ExpiresAt = new Date(data.ExpiresAt);

          return data ? Object.assign(new ShipmentItem(), data) : null;
        })
      );
  }

  getShipmentHistory(id: string): Observable<ShipmentHistory[]> {
    return this.apiService.get<ShipmentHistory[]>('Management/shipments/' + id + '/history');
  }

  getShipmentNotifications(id: string): Observable<Notification[]> {
    return this.apiService.get<Notification[]>('Management/shipments/' + id + '/notifications')
      .pipe(
        map(res => res ? res.sort((a, b) => a.Date > b.Date ? -1 : 1) : [])
      );
  }

  downloadShipmentLabel(id: string) {
    return this.apiService.get<GuiaBase64>('Management/shipments/' + id + '/label')
      .pipe(mergeMap(shipmentLabel =>
        fetch('data:image/png;base64,' + shipmentLabel.data)
          .then(res => res.blob())
          .then(blob =>
            this.fileUtil.saveFile(blob, shipmentLabel.TrackingNumber  + '.pdf')
            )
      ));
  }
  downloadShipmentNewLabel(id: string, labelType?: string) {
    if (labelType === 'URL') {
      return this.apiService.get<GuiaBase64>('Management/shipments/' + id + '/label?LabelType=' + labelType);
    } else if (labelType === 'zip') {
      return this.apiService.get<any>('Management/shipments/' + id + '/label?LabelType=' + labelType)
      .pipe(
        mergeMap(filesMultiShipment => {
          console.log('downloadMultiFilesZip : ', filesMultiShipment);
          const files = filesMultiShipment.filter(f => f.data)
            .map(f => {
              console.log('Download f: ', f);
              return {
                fileNameWithExtension: `${f.TrackingNumber}.${f.LabelType.toLowerCase()}`,
                base64Data: f.data,
                mime: f.LabelType
              };
            });

          if (files.length === 0) {
            return throwError({ message: 'No se encontraron los archivos.', severity: 'warn' });
          }
          
          return this.fileUtil.saveZipOfBase64Files(files,'shipments.zip')
            .then(() => files.map(f => f.fileNameWithExtension));
        })
      );
    } 
    else {
    return this.apiService.get<GuiaBase64>('Management/shipments/' + id + '/label')
      .pipe(mergeMap(shipmentLabel =>
        fetch('data:application/pdf;base64,' + shipmentLabel.data)
          .then(res => res.blob())
          .then(blob =>
            this.fileUtil.saveFile(blob, shipmentLabel.TrackingNumber + '.pdf')
            )
      ));
    }
  }

  patchShipmentExceptionStatus(shipmentId: string, hasExceptions: boolean): Observable<any> {
    return this.apiService.patch('Management/shipments/' + shipmentId + '/exceptionStatus?hasExceptions=' + hasExceptions);
  }
  patchShipmentLostStatus(shipmentId: string, hasLost: number): Observable<any> {
    return this.apiService.patch('Management/shipments/' + shipmentId + '/lostStatus?hasLost=' + hasLost);
  }

  postShipmentRefund(shipmentId: string): Observable<any> {
    return this.apiService.post('Management/shipments/' + shipmentId + '/refund');
  }
  postShipmentPendingTransaction(TransactionId,sComment){
    return this.apiService.post('Management/transaction/pending/'+TransactionId,{comments:sComment});
  }

  postShipmentRefundTransaction(TransactionId,sComment){
    return this.apiService.post('Management/transaction/refund/'+TransactionId,{comments:sComment});
  }

  changeGenerateUser(resellerId: string) {
    return this.apiService.patch(`Management/generate/${resellerId}`);
  }

  blockUser(userId: string) {
    return this.apiService.delete(`Management/users/${userId}`);
  }

  unblockUser(userId: string) {
    return this.apiService.post(`Management/users/${userId}/activate`);
  }

  postBalanceAdjustment(resellerId: string, adjustmentType: string, amount: number, comment: string): Observable<any[]> {
    let balanceAdjustment = { BalanceAdjustmentType: adjustmentType, Amount: amount, Comment: comment};
    return this.apiService.post<any>(`Management/Resellers/${resellerId}/balanceAdjustment`, balanceAdjustment);
  }

  createOverweightTransaction( overweightData) {
    return this.apiService.post<ShipmentOverweightTransaction>('Management/overweightTransaction', overweightData);
  }

  createExtraChargeTransaction( extraData: ExtraChargeShipmentTransaction, transacionType: string) {
    return this.apiService.post<ShipmentOverweightTransaction>(`Management/transaction/extraCharge/${transacionType}`, extraData);
  }

  getClarificationFiles(shipmentId: string) {
    return this.apiService.get<any>(`Shipments/clarificationFiles?shipmentId=${shipmentId}`);
  }

  downloadClarificationFilesZip(shipmentId: string) {
    return this.apiService.get<any[]>(`Management/filesClarification?shipmentId=${shipmentId}`)
      .pipe(
        mergeMap(filesClarification => {
          console.log('downloadClarificationFilesZip : ', filesClarification);
          const files = filesClarification.filter(f => f.File)
            .map(f => {
              console.log('Download f: ', f);
              return {
                fileNameWithExtension: f.Name,
                base64Data: f.File,
                mime: f.Mime
              };
            });

          if (files.length === 0) {
            return throwError({ message: 'No se encontraron los archivos.', severity: 'warn' });
          }

          return this.fileUtil.saveZipOfBase64Files(files,'evidencias.zip')
            .then(() => files.map(f => f.fileNameWithExtension));
        })
      );
  }

  getEmailResellers(email:string){
    const searchCriteria = {
      email,
      pageNumber: 1,
      pageSize: 10000,
  }
    return this.apiService.get<Reseller[]>('Management/resellers/byFilter', searchCriteria).pipe(
      map(resellers => resellers.map(reseller => reseller.Email))
    );
  }

  /**
   * @description Retrieves payment gateway configuration
   * @see PT-1772
   */
  getPaymentGatewayConfig() {
    return this.apiService.get<any>('Global/getPaymentGatewayConfig');
  }
}
