import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ApiService } from '.';
import { LogUtilService } from 'src/app/core/services/util/log.service';
import {
  PaymentOrderMercadoPago,
} from '../../core/models/models';

@Injectable ()

export class MercadoPagoService {

  constructor(
    private apiService: ApiService,
    private logHelper: LogUtilService
    ) { }

  sendPaymentOrder(payment: PaymentOrderMercadoPago): Observable<any> {
    const url = 'Reseller/rechargeWallet';
    this.logHelper.info('MercadoPago payment', null, payment, 'Request recharge');
    return this.apiService.post(url, payment);
  }

  saveCard(cardData: PaymentOrderMercadoPago): Observable<any> {
    const url = 'Reseller/cards/saveCard';
    this.logHelper.info('MercadoPago saveCard', null, cardData, 'Save card');
    return this.apiService.post(url, cardData);
  }
}
