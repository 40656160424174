export const environment = {
  production: false,
  apiUrl: 'https://api-seller-sandbox.pakke.mx/api/v1/',
  f360Url: 'https://full360-api-staging.pakke.mx/api/v2/',
  apittl: 43200,
  environmentCode: 'SBX_MX',

  // localization
  nameCountry: 'México',
  countryCode: 'MX',
  languageCode: 'es-MX',
  currency: 'MXN',
  timeZone: 'America/Mexico_City',
  lada: '+52',
  contactPhone: '55 4440 4861',
  lengthPhone: 10,
  taxDefault: 0.16,

  // payments & payment gateways
  paymentAmountMin: 10,
  paymentAmountMinStore: 20,
  paymentAmountMax: 100000,
  paymentAmountMinRecurrentCharge: 10,
  paymentAmountMaxRecurrentCharge: 5000,
  openPayMerchantId: 'm654udbssyb0n4cz7gxs',
  openPayApiKey: 'pk_d78d8805bb004931a98781d6eb8daad2',
  openPayUrl: 'https://sandbox-dashboard.openpay.mx/',
  etominApiKey: 'PB_key_tst_7cc3544dbbf891353b06c8eadf3df39b',
  PAYPALCLIENTID: 'AeOyITUXR1N3vN6b2Vzy0zrPs1ptmz5uRK-rMIZmDgvCrWVN2Wj5Zt1OB7q6DeczOidmxeMYufV2SjlF',/** @desciption cuenta sandbox MX  @see PAK-3765*/

  // enabled modules and features
  codigoRef: true,
  ordenesCompra: true,
  batches: true,
  pickups: true,
  bulkCollectOrder: true,
  showOnboardingData: true,
  APPLY_BONUSES: true,
  shipmentGeneration: false,
  showCheckList: false,
  sepaActive: false,
  socialNetworks: {
    showLoginOptions: true,
    showGoogle: true,
    showFacebook: true,
  },
  modules: {
    balancesAndPayments: true,
    bill: true,
    movements: true,
    tariff: true,
  },
  refund: {
    transactions: true,
  },
  dashboard: {
    shippingUtility: true,
  },

  // f360 / fulfillment
  fulfillmentBannerFlag: false,
  fulfillmentLogin: false,
  fulfillmentProduct: 'full360', // 'fullPromologistic'

  // Mkt, RRSS & 3rd party tools
  logEntryKey: '7046409a-479e-40c1-b61e-f1902b2b207e',
  googleTagManagerId: 'GTM-W2XXNW5',
  GOOGLE_CLIENT_ID: '525246605899-coisnrk247ur9hbrvojr019c3ei01sh2.apps.googleusercontent.com',
  FB_APP_ID: '600285264512968',
  metamap: {
    clientId: '621fc28c9ada5e001c10a059',
    flowId: '622251b025b825001cf50f92',
    color: '#507DED'
  },
  MAPS_API_KEY: 'AIzaSyAqZLvSE7INTx5mUcxzzx8YcqiM3nP1erI',

  // look & feel customization
  branding: {
    pageTitle: 'Pakke :: Más opciones, más envíos',
    pageDescription: 'Controla toda tu paquetería, compara los precios, imprime las guías y rastrea tus paquetes con Pakke Soluciones de Envío.',
    mainLogoUrl: 'assets/img/brand/pakke.svg',
    sidebarLogoUrl: 'assets/img/brand/pakke.svg',
    notificationLogoUrl: 'assets/img/brand/full360Logo.svg',
    sidebarFooterLogoUrl: '',
    favicon: 'favicon.ico',
    backgroundImgUrl: 'assets/img/bg-login-pakke.jpg',
    whatsappUrl: 'https://api.whatsapp.com/send?phone=525544404861&text=Hola,%20quisiera%20saber%20más%20acerca%20de%20Pakke',
    telegramUrl: 'http://t.me/PakkeMexBot'
  },
  copyright: {
    name: 'Pakke México',
    privacyPolicyUrl: 'https://help.pakke.com/hc/es/articles/360033921813-Aviso-de-Privacidad',
    tocUrl: 'https://help.pakke.com/hc/es/articles/360033400494-T%C3%A9rminos-y-Condiciones',
  },
  recaptcha: {
    siteKey: '6Le4g-YkAAAAANhCD9P1Zf7oJ3AeslOVrgvKu2-K'
  },
  paymentGateway : 'MP',
  // paymentGateway : "STRIPE",
  // mercadoPagoKey: 'APP_USR-99db420e-92a9-429c-b2cf-5e416c79ecb9'
  mercadoPagoKey: 'APP_USR-2c717663-b86e-4cf7-8218-726d8e7829a3' // Test prod, por eliminar
};
