import { Pipe, PipeTransform } from '@angular/core';
import { FormatHelperService } from 'src/app/core/services/util/format-helper.service';
import {CourierSvc} from '../../core/services';

@Pipe({
  name: 'courierLogo',
})
export class CourierLogoPipe implements PipeTransform {
  arrayImagesByCourierCode;
  constructor(private courierSvc: CourierSvc) {
    this.arrayImagesByCourierCode = courierSvc.DynamicsLogos;
  }

  transform(courierCode: any, withoutBackground: boolean = false): string {
    if (this.arrayImagesByCourierCode) {
      return this.getCourierImg(courierCode, withoutBackground);
    } else {
      this.courierSvc.loadDynamicCourierLogos().then(() => {
        return this.getCourierImg(courierCode, withoutBackground);
      });
    }
  }

  private getCourierImg(courierCode: any, withoutBackground: boolean) {
    const code = this.arrayImagesByCourierCode.find(item => item.CourierCode === courierCode || item.courierCode === courierCode);
    if (code && code.urlLogo) {
      return code.urlLogo;
    }
    return withoutBackground ? this.sendStaticLogo(courierCode) : this.sendStaticLogoForDashboard(courierCode);
  }

  sendStaticLogo(courierCode) {
    switch (courierCode) {
      case 'STF':
      case 'STFRT':
        return 'assets/img/brand/courier/estafeta.svg';
      case 'FDX':
      case 'MBE_FDX':
        return 'assets/img/brand/courier/fedexcolor-03.png';
      case 'DHL':
      case 'MBE_DHL':
      case 'DHL_PAKKE':
        return 'assets/img/brand/courier/dhl.svg';
      case '99M':
      case '99M_PAKKE':
        return 'assets/img/brand/courier/99minutos.svg';
      case 'UBER':
        return 'assets/img/brand/courier/curier_uber.png';
      case 'RPK':
        return 'assets/img/brand/courier/redpack.svg';
      case 'GLS_ESP':
        return '/assets/img/brand/noStoreLogo.png';
      case 'UPS':
      case 'UPS_ESP':
      case 'UPS_INT':
        return 'assets/img/brand/courier/ups.svg';
      case 'SRV':
        return 'assets/img/brand/courier/servientrega.svg';
      case 'RSV':
        return 'assets/img/brand/courier/redservi.svg';
      case '472':
        return 'assets/img/brand/courier/472.svg';
      case 'AEF':
        return 'assets/img/brand/logo_aeroflash.png';
      case 'APM':
        return 'assets/img/brand/courier/ampm.svg';
      case 'PEX':
      case 'MBE_PEX':
        return 'assets/img/brand/courier/pex.svg';
      case 'PKD':
        return 'assets/img/brand/courier/logo _PD.svg';
      case 'MUB':
        return 'assets/img/brand/courier/logo_mub.png';
      case 'IVOY':
        return 'assets/img/brand/courier/logo_ivy.png';
      case 'JTE':
        return 'assets/img/brand/courier/jte.svg';
      case 'IMILE':
        return 'assets/img/brand/courier/imile.png';
      case 'QKN':
        return 'assets/img/brand/courier/quiken_color.svg';
      case 'MLE':
        return 'assets/img/brand/courier/logo_mercadolibre.svg';
      case 'SDX_MEX':
        return 'assets/img/brand/courier/sendex.svg';
      case 'BMS':
          return 'assets/img/brand/courier/logo_bms.svg';
      case 'TGL':
        return 'assets/img/brand/courier/tgl.png';
      case 'LAM':
        return 'assets/img/brand/courier/lam.svg';
      case 'MOOVA':
        return 'assets/img/brand/courier/moova.svg';
      // Not implemented
      case 'aeromar':
        return 'assets/img/brand/courier/notimplemented/aeromarCarga.png';
      case 'aeromexico':
        return 'assets/img/brand/courier/notimplemented/aeromexicoCargo.svg';
      case 'afimex':
        return 'assets/img/brand/courier/notimplemented/afimex.png';
      case 'altospack':
        return 'assets/img/brand/courier/notimplemented/altosPack.png';
      case 'bajapack':
        return 'assets/img/brand/courier/notimplemented/bajaPack.png';
      case 'dypaq':
        return 'assets/img/brand/courier/notimplemented/dyPaq.png';
      case 'entrega':
        return 'assets/img/brand/courier/notimplemented/entrega.png';
      case 'estrellablanca':
        return 'assets/img/brand/courier/notimplemented/enviaEstrellaBlanca.png';
      case 'primeraplus':
        return 'assets/img/brand/courier/notimplemented/enviosPrimeraPlus.png';
      case 'expresscontinental':
        return 'assets/img/brand/courier/notimplemented/expressContinental.png';
      case 'expressMG':
        return 'assets/img/brand/courier/notimplemented/expressMG.png';
      case 'flechaamarilla':
        return 'assets/img/brand/courier/notimplemented/flechaamrilla.png';
      case 'carssa':
        return 'assets/img/brand/courier/notimplemented/grupoCarssa.png';
      case 'inbox':
        return 'assets/img/brand/courier/notimplemented/inbox.png';
      case 'jetPaqueteria':
        return 'assets/img/brand/courier/notimplemented/jetPaqueteria.png';
      case 'masAprisa':
        return 'assets/img/brand/courier/notimplemented/masAprisa.png';
      case 'senda':
        return 'assets/img/brand/courier/notimplemented/mexico-senda-express.svg';
      case 'mexpost':
        return 'assets/img/brand/courier/notimplemented/mexPost.jpg';
      case 'multipack':
        return 'assets/img/brand/courier/notimplemented/multiPack.jpg';
      case 'odmexpress':
        return 'assets/img/brand/courier/notimplemented/odmExpress.jpeg';
      case 'packetn':
        return 'assets/img/brand/courier/notimplemented/packETN.png';
      case 'packmail':
        return 'assets/img/brand/courier/notimplemented/pakMail.png';
      case 'castores':
        return 'assets/img/brand/courier/notimplemented/paqueteriaCastores.jpg';
      case 'estrellaroja':
        return 'assets/img/brand/courier/notimplemented/paqueteriaEstrellaRoja.jpg';
      case 'tamazula':
        return 'assets/img/brand/courier/notimplemented/paqueteriaTamazula.jpeg';
      case 'pfs':
        return 'assets/img/brand/courier/notimplemented/pfs.png';
      case 'potosinos':
        return 'assets/img/brand/courier/notimplemented/potosinos.jpg';
      case 'csmpaqueteria':
        return 'assets/img/brand/courier/notimplemented/scmPaqueteria.png';
      case 'tap':
        return 'assets/img/brand/courier/notimplemented/tapEnvios.png';
      case 'tinypack':
        return 'assets/img/brand/courier/notimplemented/tinyPack.jpg';
      case 'transportesleon':
        return 'assets/img/brand/courier/notimplemented/transportesLeon.jpeg';
      case 'transUrge':
        return 'assets/img/brand/courier/notimplemented/transUrge.jpeg';
      case 'tresguerras':
        return 'assets/img/brand/courier/notimplemented/tresGuerras.png';
      case 'tufesa':
        return 'assets/img/brand/courier/notimplemented/tufesa.jpeg';
      case 'volaris':
        return 'assets/img/brand/courier/notimplemented/volarisCarga.jpg';
      case 'otro':
        return 'assets/img/brand/courier/notimplemented/camionRR-04.png';
      default:
        return '';
    }
  }

  sendStaticLogoForDashboard(courierCode: string) {
    const basePaht = 'assets/img/brand/_courier/';
    switch (courierCode) {

      case 'STF':
      case 'STFRT':
        return basePaht.concat('stf.png');
      case 'FDX':
      case 'MBE_FDX':
        return basePaht.concat('fdx.png');
      case 'DHL':
      case 'MBE_DHL':
      case 'DHL_PAKKE':
        return basePaht.concat('dhl.png');
      case '99M':
      case '99M_PAKKE':
        return basePaht.concat('99m.png');
      case 'RPK':
        return basePaht.concat('rpk.png');
      case 'GLS_ESP':
        return basePaht.concat('gls.png');
      case 'UPS':
      case 'UPS_ESP':
      case 'UPS_INT':
        return basePaht.concat('ups.png');
      case 'SRV':
        return basePaht.concat('srv.png');
      case 'RSV':
        return basePaht.concat('redservi.png');
      case '472':
        return basePaht.concat('472.png');
      case 'AEF':
        return 'assets/img/brand/logo_aeroflash.png';
      case 'APM':
        return basePaht.concat('apm.png');
        case 'PEX':
      case 'MBE_PEX':
        return basePaht.concat('pex.png');
      case 'PKD':
        return basePaht.concat('pkd.png');
      case 'MUB':
        return basePaht.concat('mub.png');
      case 'IVOY':
        return basePaht.concat('ivoy.png');
      case 'JTE':
        return basePaht.concat('jte.png');
      case 'LAM':
        return basePaht.concat('lam.png');
      case 'MOOVA':
        return basePaht.concat('moova.png');
      case 'IMILE':
        return basePaht.concat('imile.png');
      case 'QKN':
        return basePaht.concat('qkn.png');
      case 'MLE':
        return basePaht.concat('mle.png');
      case 'SDX_MEX':
        return basePaht.concat('sdx_mex.png');
      case 'UBER':
        return basePaht.concat('uber.png');
      case 'BMS':
        return basePaht.concat('bms.png');
      case 'TRG_MEX':
        return basePaht.concat('trg_mex.png');
      case 'TGL':
        return basePaht.concat('tgl.png'); 
      case 'CEX_ESP':
        return basePaht.concat('express.png');
      case 'CRR_ESP':
        return basePaht.concat('correos.png');
      case 'EVI_ESP':
        return basePaht.concat('envialia.png');
      case 'SEU_ESP':
        return basePaht.concat('seur.png');
      default:
        return '';
    }
  }
}
