import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ViewChild, Type, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { GrowlSvc } from '../../core/services/growl.service';
import { UserService } from '../../core/services/user.service';
import { AppMenuService, MenuItem } from '../../core/services/app-menu.service';
import { Password, LoggedUserState, Reseller, OnBoardingInfo, GlobalConfigData, AccessTokenInfo } from '../../core/models/models';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, delay, takeUntil, finalize, take, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LayoutService } from './service/layout.service';
import { Layout, LayoutModels } from './model/layout';
import { CourierSvc, FullfillmentService, ResellerService } from 'src/app/core/services';
import { Location } from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { OnBoardingComponent } from 'src/app/on-boarding/on-boarding.component';
import { OnBoardingService } from 'src/app/on-boarding/service/on-boarding.service';
import { ModalNotifyComponent } from 'src/app/shared/components/modal-notify.component';
import { ModalComponent } from 'src/app/shared/components/modal.component';
import { ModalOnboardingComponent } from 'src/app/shared/components/modals/modal-onboarding/modal-onboarding.component';
import { ResellerAlertBanner, TypeAlertBanner } from 'src/app/core/models/store.model';
import { FormatHelperService } from '../../core/services/util/format-helper.service';
import { NotificationObsService } from 'src/app/core/services/sharing/notification-obs.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ProfileObsService } from 'src/app/core/services/sharing/profile-obs.service';
import { ShipmentObsService } from 'src/app/core/services/sharing/shipment-obs.service';
import { ModalXLComponent } from 'src/app/shared/components/modal-xl-component';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';
import { DateTime } from 'luxon';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from 'src/app/core/services/util/form-helper.service';
import { PakkeVerifyStore } from 'src/app/shared/components/pakke-verify/pakke-verify.store';

const routeToMap = new Map([
  [ 'orden', 1 ],
  [ 'B', 2 ],
  [ 'C', 3 ]
]);

@Component({
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(90deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('onBoardingPanel', { static : false } ) onBoardingPanel: OnBoardingComponent;
  @ViewChild(ModalNotifyComponent, { static: true }) private notifyModal: ModalNotifyComponent;
  @ViewChild(ModalComponent, { static: true }) private orderSKUModal: ModalComponent;
  @ViewChild(ModalOnboardingComponent, { static: true}) private onboardingModal: ModalOnboardingComponent;
  @ViewChild('mainTitlePopover', {read: ElementRef, static: true}) mainTitlePopover: ElementRef;
  @ViewChild('successModal', { static: true }) private successModal: ModalComponent;
  @ViewChild('massiveGuides', { static: true }) private massiveGuides: ModalXLComponent ;
  @ViewChild('chgPwdModal',{ static: true }) chgPwdModal: any;

  isDataReady: boolean = false;
  chgPwd: boolean = false;
  sideOpened = true;
  user$: Observable<LoggedUserState>;
  passwordManager = new Password();
  ordenesCompra = environment.ordenesCompra;
  batches = environment.ordenesCompra;
  pickups = environment.pickups;
  shipmentGeneration = environment.shipmentGeneration;
/*Atributos para actualizar el menú */
  mainMenuItems;
  navTitle: string;
  navIcon: string;
  navRoute: string;
  isPopover: boolean = false;
  hasHelperTemplate: boolean = false;
  helperTemplate: string = '';
  activeMenuItem$: Observable<MenuItem>;
  layoutSet: Layout = null;
  CountryCode = environment.countryCode;
  resellerInfo: Reseller;
  resellerNotify = false;
  teamUser = false;
  isFullfillment: boolean = false;
  fullfilmentBannerFlag = !!environment.fulfillmentBannerFlag;
  createCouriersAccounts: boolean = true;
  expanded: boolean=false;
  get faIcon (): boolean {
    return (this.navIcon && this.navIcon.indexOf('fa-') > -1);
  }
  onBoardingInfo: OnBoardingInfo;
  infoNotify: GlobalConfigData;
  private completePercentage: number;
  private listAlertBanner: ResellerAlertBanner[] = [];
  flagOnboardingShow = false;
  fulfillmentProduct = environment.fulfillmentProduct;
  statusOnboarding: number = 3;
  verificationStatus: string = '';
  isDuplicatePhone: boolean = false;
  button: any;
  opts = environment;
  countNewNotifications = 0;
  shipmentResult: any = null;
  massiveShipmentObs: any = null;
  valueAnswer: number = 0;
  shipmentsResponse: any = [];
  userState: any;
  daysRemaining: number | undefined;
  bannerType: string | undefined
  currencySymbol = environment.countryCode === "MX" ? "MXN" : "EUR";
  languageCode = environment.languageCode;
  currentBalance: number = 0

  private userSubject = new BehaviorSubject<LoggedUserState>(new LoggedUserState());

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  public currentDate = new Date();
  progressMasive = 0;
  // isStatusVerified:boolean = false;

  // TODO: PROD TESTING
  accessToken: AccessTokenInfo;
  newSellerUrl: string;
  userResellerId: string; // TODO: PROD TESTING
  resellersOfNewFeatures = []
 $onDestroy: Subject<void> = new Subject<void>(); // TODO: PROD TESTING
  showAlertButton: boolean;

 passwordForm: FormGroup | undefined;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _authSvc: AuthenticationService,
    private _growlSvc: GrowlSvc,
    private _userSvc: UserService,
    public _fullfillmentService: FullfillmentService,
    private menuService: AppMenuService,
    private router: Router,
    public layoutService: LayoutService,
    private resellerService: ResellerService,
    private loc: Location,
    private onBoardingService: OnBoardingService,
    private courierService: CourierSvc,
    private formatHelp: FormatHelperService,
    private elementRef:ElementRef,
    public notificationObsService: NotificationObsService,
    private notificationService: NotificationService,
    private profileObsService: ProfileObsService,
    private shipmentObsService: ShipmentObsService,
    private resellerObsService: ResellerObsService,
    private fb: FormBuilder,
    private formHelper: FormHelperService,
    private pakkeVerifyStore: PakkeVerifyStore
    ) {
      this.user$ = _authSvc.user$;
      this.resellerService.getBalanceObs().pipe(takeUntil(this.$onDestroy)).subscribe((balance) => this.currentBalance = balance)
      /*Actualizar la cabecera del menu*/
      this.updateMenu();
      this.menuService.setCustomIcon();
      this.menuService.menuChanged.pipe(takeUntil(this.$onDestroy)).subscribe((any) => {
        this.updateMenu();
      });
    }


  ngOnInit(): void {
    this.createPasswordForm();
      this._authSvc.user$.pipe(takeUntil(this.$onDestroy)).subscribe(async (userState) => {
        this.userState = userState;
        if (!this.userState.isHelpDesk) {
          this.resellerService.getBalance().pipe(take(1)).subscribe();
        }
        this.resellerObsService.setUserStateObservable = userState;

        this.teamUser = userState.isResellerOwner;
        this.layoutService.editable = userState.catEdit;
        this.layoutService.owner = userState.isResellerOwner;
        this.isFullfillment = userState.isFullfillment;
        this.flagOnboardingShow = environment.showOnboardingData;

        if (userState && (userState.isReseller || userState.isFullfillment)) {
          this.InitNotifiObservables();
            this.resellerService.getAuthReseller(false).pipe(takeUntil(this.$onDestroy)).subscribe(data => {
              this.resellerObsService.setResellerObservable = data;
              if (data && data.resellerPriceGroup && data.resellerPriceGroup.priceGroupBase
                && (data.resellerPriceGroup.priceGroupBase.PriceGroupId === 'DEFAULT'
                  || data.resellerPriceGroup.priceGroupBase.PriceGroupId === 'MARGEN20')) {
                this.resellerInfo = data;
                // Todos los usuarios deben visualizar el engrane
                // FIX Todos los usuarios pueden ver cuentas personalizadas
                // this.createCouriersAccounts = this.resellerInfo.CreateCouriersAccounts;
              }
              if (data  && data.ShouldUpdateBillingInfo ) {
                this._authSvc.setShowAlertButtonState(data.ShouldUpdateBillingInfo); 
              }
              if(data){
                this._authSvc.setShowBtnClearResellerInfo(data.globalconfig.EnableBilling)
              }
            })
            this._authSvc.showAlertButton$.subscribe(state => {
              this.showAlertButton = state;
            });
        }

        if (this.CountryCode === "MX" && this.isFullfillment) {
          const listOrderWithOutSKU = await this._fullfillmentService
            .getCountOrderWithoutSKU()
            .toPromise();
          const listStoreDisconnected = await this._fullfillmentService
            .getStoreDisconnected()
            .toPromise();

          this.listAlertBanner = [];

          if (listStoreDisconnected && listStoreDisconnected.length > 0) {
            listStoreDisconnected.forEach((store) => {
              const newAlertBanner: ResellerAlertBanner = {
                type: TypeAlertBanner.StoreDisconnected,
                value: store.StoreId,
                resellerStoreId: store.ResellerStoreId
              };

              this.listAlertBanner = [...this.listAlertBanner, newAlertBanner];
            }, this);
          }

          this._fullfillmentService.orderWithOutSku = listOrderWithOutSKU.length;
          if (listOrderWithOutSKU.length > 0 && this.router.url === "/fulfillment/dashboard") {
            const newAlertBanner: ResellerAlertBanner = {
              type: TypeAlertBanner.OrderWarning,
              value: listOrderWithOutSKU.length,
            };
            this.listAlertBanner = [...this.listAlertBanner, newAlertBanner];
            // this.orderSKUModal.show(); // MODAL DESACTIVADO
          }

          this._fullfillmentService.addAlertsBanner(this.listAlertBanner);
        } else {
          // this.orderSKUModal.hide(); // MODAL DESACTIVADO
        }

        if ( !userState || !userState.resellerGlobalConfig) { return ; }
        if (environment.ordenesCompra) {
          this.ordenesCompra = !!userState.resellerGlobalConfig.Orden_Compra;
        } else {
          this.ordenesCompra = environment.ordenesCompra;
        }
        this.shipmentGeneration = !!userState.resellerGlobalConfig.ShipmentGeneration;
      }
    );
    this.layoutService.layoutSet.pipe(delay(0),takeUntil(this.$onDestroy)).subscribe( layout => {
      this.layoutSet = layout;
      this.navTitle = layout.title;
      this.navIcon = layout.navIcon;

      if (layout && layout.helperTemplate){
        this.hasHelperTemplate = true;
        this.helperTemplate = layout.helperTemplate
      }
      else {
        this.hasHelperTemplate = false;
        this.helperTemplate = '';
      }


      if(this.isFullfillment){
        this.orderFulfillmentWOSku();
      }

      if ((!this.layoutSet.actions || !this.layoutSet.actions.length) && this.layoutSet.israteMode == null) {
        this.layoutSet.actions = []
        if (this.shipmentGeneration && this.layoutService.editable) {
          this.layoutSet.actions.push(
            {
              name: 'generateShipment',
              label: 'dashboard.generateGuide',
              url: '/guias/generar'
            },
          );
        }
      }
      if ((!this.shipmentGeneration || !this.layoutService.editable) && this.layoutSet && this.layoutSet.actions) {
        this.layoutSet.actions = this.layoutSet.actions.filter(item => {
          return item.url.indexOf('/guias/generar') < 0 && item.label !== 'Generar una guía';
        });
      }
    });
    this.layoutService.sideToggle.pipe(takeUntil(this.$onDestroy)).subscribe(val => {
      this.sideOpened = val;
    });
    this.isHandset$.pipe(takeUntil(this.$onDestroy)).subscribe( data => {
      this.sideOpened = !data;
    });
    this.getOnboardingInfo();
    this.layoutService.onboardingUpdate.pipe(takeUntil(this.$onDestroy)).subscribe((s) => {
      this.getOnboardingInfo();
    });

    this.getLogosCouriers();

    if(this.isFullfillment) {
      window.addEventListener('click', (e: any) => {
        if (!this.mainTitlePopover.nativeElement.contains(e.target)) {
          this.isPopover = false;
        }
      });
    }

    this.massiveShipmentObs = this.shipmentObsService.getMassiveShipmentObservable;
    this.massiveShipmentObs.pipe(takeUntil(this.$onDestroy)).subscribe(data => {
      this.shipmentResult = data;

      if (Array.isArray(data) && typeof(data[0].created) !== 'undefined' && (data[0].created === true || data[0].created === false)) {
        this.shipmentsResponse.push(data[0]);
        this.progressMasive = this.progressMasive + this.valueAnswer;
        if (this.progressMasive === 0) {
          this.shipmentObsService.setMassiveProcessObservable = 'stop';
          this.shipmentResult = null;
        }
      } else if (Array.isArray(data) && data[0].OrderId && this.progressMasive === 0) {
        this.shipmentObsService.setMassiveProcessObservable = 'processing';
        this.valueAnswer = 100/(data.length + 1);
        setTimeout(() => {
          this.progressMasive = this.valueAnswer;
        }, 2000);
      }

      if (this.progressMasive === 100) {
        setTimeout(() => {
          this.shipmentObsService.setMassiveProcessObservable = 'end';
          this.shipmentResult = null;
          this.progressMasive = 0;
          this.massiveGuides.show();
          this.shipmentObsService.setShipmentsObservable = this.shipmentsResponse;
        }, 2000);
      }

    });

    this.courierService.loadDynamicCourierLogos();

    // TODO: PROD TESTING (CONEXIÓN NUEVO SELLER)
    this._authSvc.user$.pipe(takeUntil(this.$onDestroy)).subscribe(userState => {
    this.userResellerId = userState.resellerGlobalConfig.ResellerId;
    } );
    // Asignación de Token y URL (Generar pickups)
    this.accessToken = this._authSvc.getTokenInfo();
    const token = this.accessToken? this.accessToken.token : '0'
    this.newSellerUrl = `https://www.app.pakke.${environment.countryCode.toLowerCase()}/external?token=${token}&path=pickup`;

    this.profileObsService.getOnboardingObservable.pipe(takeUntil(this.$onDestroy)).subscribe((data) => {
      if (data) {
        const dateCurrent = DateTime.local();
        if (data.verificationLimit && !data.isVIPVerification) {
          const verificationLimit: DateTime = DateTime.fromISO(data.verificationLimit);
          this.daysRemaining = Math.ceil(verificationLimit.diff(dateCurrent, 'days').toObject().days);
          this.bannerType = 'daysRemainingHeader'
        }
      }
    });

    this.getStatusVerifiedStore();
  }

  ngAfterViewInit() {
    this.button = this.elementRef.nativeElement.querySelector('mati-button');

    if (this.button) {
      // setup callbacks
      this.button.addEventListener('mati:loaded', ({ detail }) => {
        console.log('loaded payload', detail);
      });
      this.button.addEventListener('mati:userFinishedSdk', ({ detail }) => {
        console.log('finished payload', detail);
        this.successModal.show();
      });
      this.button.addEventListener('mati:exitedSdk', ({ detail }) => {
        console.log('exited payload', detail);
      });
    }
  }

  ngOnDestroy() {
    this.shipmentResult = null;
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  orderFulfillmentWOSku() {
    this._fullfillmentService.getCountOrderWithoutSKU().pipe(takeUntil(this.$onDestroy)).subscribe(res => {
      this._fullfillmentService.orderWithOutSku = res.length;
      // DESACTIVADO: Usado para banner de alertas
      // const dates: DateTime[] = res.map(order => DateTime.fromISO(order.CreatedAt, { zone: 'utc-5' }));
      // const minDate: DateTime | undefined = DateTime.min(...dates);
      // const maxDate: DateTime | undefined = DateTime.max(...dates);

      // if (minDate && maxDate) {
      //     const minDateAd:string = minDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO();
      //     const maxDateAd:string = maxDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toISO();
      //     // const minDateStr: string = minDateAd.toISO();
      //     // const maxDateSrt: string = maxDateAd.toISO();
      //     this._fullfillmentService.orderWithOutSkuFirst = minDateAd;
      //     this._fullfillmentService.orderWithOutSkuSecond = maxDateAd;
      // }
    });
  }
  async StoreDisconnect() {
    const listStoreDisconnected = await this._fullfillmentService
      .getStoreDisconnected()
      .toPromise();

    this.listAlertBanner = [];

    if (listStoreDisconnected && listStoreDisconnected.length > 0) {
      listStoreDisconnected.forEach((store) => {
        const newAlertBanner: ResellerAlertBanner = {
          type: TypeAlertBanner.StoreDisconnected,
          value: store.StoreId,
          resellerStoreId: store.ResellerStoreId
        };

        this.listAlertBanner = [...this.listAlertBanner, newAlertBanner];
      }, this);
    }
    this._fullfillmentService.addAlertsBanner(this.listAlertBanner);
  }

  getLogosCouriers(){
    this.courierService.getCouriersDynamics().pipe(takeUntil(this.$onDestroy)).subscribe(couriers => {
      const dynamicsLogos = [];
      couriers.map(item => {
        const configDynamic: any = this.formatHelp.getValidJSON(item.DynamicConfig);
        const urlValid = (configDynamic && configDynamic.body && configDynamic.body.urlLogo)
          ? configDynamic.body.urlLogo : '/assets/img/brand/noStoreLogo.png';
        dynamicsLogos.push({
          CourierCode: item.CourierCode,
          urllogo: urlValid
        });
      });
    });
  }

  getOnboardingInfo() {
      if (!this.userState.isHelpDesk && this.userState.isReseller) {
        this.onBoardingService.getOnBoardingInfo().pipe(takeUntil(this.$onDestroy),
          finalize(() => {
            this.isDataReady = true;
          })
          ).subscribe(data => {
          this.onBoardingInfo = data;
          this.profileObsService.setOnboardingObservable = this.onBoardingInfo;
          this.verificationStatus = this.onBoardingInfo.status;
          this.isDuplicatePhone = this.onBoardingInfo.isDuplicatePhone;
          this.onBoardingService.setResellerStatus(this.onBoardingInfo.status);
          this.onBoardingService.setVerificationAllowed(true);
          this.onBoardingPanel.setOboardingData(this.onBoardingInfo);
          this.onBoardingService.setOnboardingData(this.onBoardingInfo);
          this.completePercentage = this.onBoardingInfo.completePercentage;
          this.showModals();

          this._authSvc.updateShipmentGeneration(data.status === 'VERIFIED' ? true : false);
          this.shipmentGeneration = (data.status === 'VERIFIED' ? true : false);
        }, err => {
          this._growlSvc.growlError(err);
        });
      }
  }




  action(action: LayoutModels.Action) {
    this.layoutService.exeAction(action);
  }

  navigate( route: string ) {
    this._authSvc.setShowModalState(false);
    if (route !== 'fulfillment/tiendas' && route !== 'fulfillment/proveedores'
      && route !== 'fulfillment/categorias' && route !== 'fulfillment/etiquetas') {
      this.expanded = false;
    }
    if (window.innerWidth < 960) { this.sideOpened = false; }
    // DESACTIVADO: Usado para banner de alertas
    // if (route === 'fulfillment/ordenes') {
    //   this.ordersStore.saveGetOrdersLink(true);
    // }
    this.router.navigate([`/${route}`]);
  }

  expande(dato: string){
    if(dato=="fulfillment/configuration"  || dato=="fulfillment/tiendas")
    {this.expanded=!this.expanded;}
    else{
      this.expanded=false;
    }
  }
  navigateOrder( route: string ) {
    // TODO: Se dejara comentado temporalmente hasta que se valide un buen funcionamiento en PROD
    // this._authSvc.user$.subscribe(
    //   userState => {
        if(this.userState.isFullfillment)
        {
          this.router.navigate([`/fulfillment/orders`]);
        }
        else
        {
          if (window.innerWidth < 960) { this.sideOpened = false; }
          this.router.navigate([`/${route}`]);
        }
      // });
  }

  navigateMenu(route: string, amount?:boolean) {
    if (this.isFullfillment) {
      if (amount) {
        this.router.navigate([`fulfillment/saldo-y-pagos`]);
      } else {
        this.router.navigate([`/fulfillment/perfil`]);
      }
    }
    else {
      this.router.navigate([`/${route}`]);
    }

  }

  isActive(route: string, strict : boolean = false): boolean {
    if(strict){
      return this.router.url === route;
    }
    return this.router.url.indexOf(route) === 0;
  }

  logout() {
    this.notificationObsService.setInboxBannerObservable = false;
    this._authSvc.logout().pipe(takeUntil(this.$onDestroy)).subscribe();
  }

  changePwd() {
    if(this.passwordForm.valid) {
      const password = {
        oldPassword: this.passwordForm.controls['oldPassword'].value,
        newPassword: this.passwordForm.controls['newPassword'].value
      }
      this._userSvc
      .changePassword(password)
      .pipe(takeUntil(this.$onDestroy)).subscribe(
        res => {
          this._growlSvc.growlSuccess('Contraseña actualizada');
          this.chgPwd = false;
          this.chgPwdModal.hide();
          this.clearPwd();
        },
        err => this._growlSvc.growlError(err)
      );
    } else {
      this.formHelper.validateFormGroup(this.passwordForm);
    }
  }

  clearPwd() {
    this.passwordForm.controls['newPassword'].reset();
    this.passwordForm.controls['oldPassword'].reset();
    this.passwordForm.controls['newPassword2'].reset();

  }

  copyReferalCode() {
    const angularRoute = this.loc.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${domainAndApp}/registro?referralCode=${this.resellerInfo.Code}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._growlSvc.growlSuccess('Código copiado');
  }

  private updateMenu() {
    this.mainMenuItems = this.menuService.getMenuItems();
    this.activeMenuItem$ = this.menuService.activeMenuItem$;
  }

  showOnBoarding() {
    if (this.onBoardingInfo && this.flagOnboardingShow) {
      this.onBoardingPanel.show(this.onBoardingInfo);
    }
  }

  getDateLocalStorage(){
    let dFecha = new Date();
    return dFecha.getFullYear().toString()+"-"+dFecha.getMonth().toString()+"-"+dFecha.getDay().toString();
  }

  notifyModalClose(){
    localStorage.setItem("notifyDay",this.getDateLocalStorage());
    this.notifyModal.hide();
  }

  navigateTo(route: string) {
    if (this.isFullfillment) {
      this.router.navigate([`/fulfillment/${route}`]);
    } else {
      this.router.navigate([`/${route}`]);
    }
  }

  currentRouteIs(routeMatch: string): boolean {
    return this.router.url === routeMatch;
  }

  private showNotifyModel(){
    let sNotifyDate = localStorage.getItem("notifyDay");
    let sDate = this.getDateLocalStorage();

    if(sNotifyDate!=sDate && !this.isFullfillment){
      this.notifyModal.show();
    }else{
      this.notifyModal.hide();
      this.showOnboardingModal()
    }
  }

  onboardingModalClose(){
    localStorage.setItem("notifyDay",this.getDateLocalStorage());
    this.onboardingModal.hide();
  }

  private showOnboardingModal() {
    // Con la implementacion de metamap solo es necesario validar que estatus tiene el reseller en su verificacion,
    // antes para poder mostrar el mensaje de onboarding era necesario que el perfil no estuviera al 100% de avance
    if (this.verificationStatus !== 'VERIFIED') {
      let sOnboardingDate = localStorage.getItem("notifyDay");
      let sDate = this.getDateLocalStorage();

      if(sOnboardingDate!=sDate && (this.CountryCode === "MX") && !this.isFullfillment){
        this.onboardingModal.show();
      }else{
        this.onboardingModal.hide();
      }
    }
  }

  private async showModals(){
    let showNotifyModel: boolean = false;
    const dataNotify = await this.resellerService.getResellerConfigCommercial().toPromise()

    if(dataNotify && dataNotify.statusCode && dataNotify.statusCode == 200 && dataNotify.ConfigData){
      if(dataNotify.ConfigData.ConfigBody && dataNotify.ConfigData.ConfigBody.urlToDesktop
        && dataNotify.ConfigData.ConfigBody.urlToMobile){
        this.infoNotify = dataNotify;
        showNotifyModel = true;
      }
    }

    if (showNotifyModel){
      this.showNotifyModel();
      return;
    }

    this.showOnboardingModal();
  }

  public get typeAlert(): typeof TypeAlertBanner {
    return TypeAlertBanner;
  }

  successModalClose(){
    this.successModal.hide();
    this.router.navigate(['/']);
  }

  InitNotifiObservables() {

    this.notificationService.getInboxHistory().pipe(takeUntil(this.$onDestroy)).subscribe((history) => {
      let countTemp = history.filter(x => x.Seen == false).length;
      this.countNewNotifications = countTemp < 100 ? countTemp : 99;
      this.notificationObsService.setNotificaionObservable = history;
    });

    this.notificationObsService.getClearIconInboxObservable.pipe(takeUntil(this.$onDestroy)).subscribe(clear => {
      if (clear && this.countNewNotifications > 0) {
        this.countNewNotifications--;
      }
    });

    this.notificationObsService.getcountNewNotifiObservable.pipe(takeUntil(this.$onDestroy)).subscribe(count => {
      this.countNewNotifications = count < 100 ? count : 99;
    });

  }

  modalConfirm() {
    this.massiveGuides.hide();
    this.shipmentsResponse = [];
    this.shipmentObsService.setMassiveShipmentObservable = null;
  }

/**
 * @description Create passwordForm
 * @see PT-1063
 */
createPasswordForm() {
  this.passwordForm = this.fb.group ({
    oldPassword: [null],
    newPassword: [null,Validators.compose([Validators.required, this.passwordComplexityValidator])],
    newPassword2: [null,Validators.compose([Validators.required, this.passwordComplexityValidator])]
  })
}

  /**
   *
   * @param control
   * @description Password validator
   * @see PT-1063
   */
  private passwordComplexityValidator(control: FormControl): { [key: string]: boolean } | null {
    const regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/;

    const result = regex.test(control.value);
    if (!result) {
      return {
        passwordComplexity: !result // false = theres no error
      };
    } else {
      return null;
    }
  }

    /**
   * @description Password compare
   * @see PT-1063
   */
    arePasswordEqual() {
      const pass1 = this.passwordForm.get('newPassword').value;
      const pass2 = this.passwordForm.get('newPassword2').value;
      return pass1 === pass2;
    }

    /**
     *
     * @param value
     * @description Format currency
     * @see PT-1456
     * @returns
     */
    formatCurrency(value) {
      return this.formatHelp.formatCurrency(this.languageCode, this.currencySymbol, value);
    }

    /**
     * @description Get verification status from store
     * @see PT-1589
     */
    getStatusVerifiedStore() {
      this.pakkeVerifyStore.getVerifiedStatus().pipe(takeUntil(this.$onDestroy))
      .subscribe({
        next: (status) => {
          if (status === 'VERIFIED') 
            {
              // this.isStatusVerified = true;
              this.getOnboardingInfo();
              const actionExists = this.layoutSet.actions.find(action => action.name === 'generateShipment');
              if (!actionExists) {
                this.layoutSet.actions.push({
                  name: 'generateShipment',
                  label: 'dashboard.generateGuide',
                  url: '/guias/generar'
                });
              }
            }
        }
      })
    }
}



function isLoginUrl(url: string): boolean {
  return url.toLowerCase().startsWith('/login') || url.toLowerCase().startsWith('login');
}

