import {Inject, Injectable} from '@angular/core';

import { AccessTokenInfo, LoggedUserState, ResellerBalance, ResellerBalanceBreakdown } from '../models/models';
import { Observable, BehaviorSubject, timer, fromEvent, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { tap, finalize, catchError, map, mergeMap, retry } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';
import { GrowlSvc } from './growl.service';
import { FormatHelperService } from './util/format-helper.service';
import { AuthService } from "angularx-social-login";
import { StoreService } from 'src/app/views-reseller/store/service/store.service';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class
AuthenticationService {
  readonly user$: Observable<LoggedUserState>;
  fullfillment = localStorage.getItem('fullfillment') == '1' ? true : false;
  fulfillmentLogin = !!environment.fulfillmentLogin;
  private userSubject = new BehaviorSubject<LoggedUserState>(new LoggedUserState());
  private cachedAccessToken: AccessTokenInfo = null;
  private isSesionActive$ = new Subject<boolean>();
  main$ = this.isSesionActive$.asObservable();

  private showAlertModalSubject$ = new BehaviorSubject<boolean>(true);
  showModal$ = this.showAlertModalSubject$.asObservable();

  private showAlertButtonSubject = new BehaviorSubject<boolean>(false);
  showAlertButton$ = this.showAlertButtonSubject.asObservable();

  private showClearResellerBtnSubject = new BehaviorSubject<boolean>(false);
  showClearResellerBtn$ = this.showClearResellerBtnSubject.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private cookieService: CookieService,
    private comunService: CommonService,
    private _growlSvc: GrowlSvc,
    private formatService: FormatHelperService,
    private socialAuthService: AuthService,
    private storeService: StoreService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.user$ = this.userSubject.asObservable();
    this.setInitialUserStateFromStorage();

    const storageChange$ = timer(1000).pipe(mergeMap(() => fromEvent(window, 'storage')));
    storageChange$.subscribe(this.onStorageChanged);

  }

  setShowAlertButtonState(state: boolean) {
    this.showAlertButtonSubject.next(state);
  }

  setShowModalState(state: boolean) {
    this.showAlertModalSubject$.next(state);
  }

  setShowBtnClearResellerInfo(state: boolean) {
    this.showClearResellerBtnSubject.next(state);
  }

  setWorkingSesion(isSesion: boolean) {
    this.isSesionActive$.next(isSesion);
  }

  login(credentials: { email: string, password: string, ttl: number, fullfillment: boolean }, url: any): Observable<any> {
    const $this = this;
    return this.apiService.post('Users/login', credentials).pipe(
      tap(token => {
        const tokenInformation = Object.assign(new AccessTokenInfo(), token, { user: credentials.email });
        localStorage.setItem('accessToken', JSON.stringify(tokenInformation));
        $this.cookieService.set('accessToken', JSON.stringify(tokenInformation));
        $this.cachedAccessToken = token;
        const userState = Object.assign(
          new LoggedUserState(),
          {
            user: credentials.email,
            balance: null,
            paymentMode: null,
            balanceIsOutdated: false,
            authenticated: true,
            role: $this.cachedAccessToken.roles[0],
            resellerGlobalConfig: token.ResellerGlobalConfig
          });

          localStorage.removeItem('fullfillment');
          if (userState.role =='FULLFILLMENT') {
            localStorage.setItem('fullfillment', "1");
          } else {
            localStorage.setItem('fullfillment', "0");
          }
          if (userState.role === 'HELP_DESK') {
            const botHTML = document.getElementById('telegramHTML');
            botHTML.style.display = 'none';
            $this.updateUserState(userState);
            const returnUrl = $this.route.snapshot.queryParamMap.get('returnUrl');
            if (returnUrl) {
              $this.router.navigate([returnUrl], { replaceUrl: true });
            } else {
              if (url == 'fulfillment/login' || this.fulfillmentLogin) {
                $this.localLogout();
              } else {
                $this.navigateToHome();
              }
            }
            return ;
          }
          this.comunService.getMyConfig().subscribe(config => {
            if (config) {
              userState.resellerGlobalConfig = config;
            }

          }, err => {},
          async () => {
            $this.updateUserState(userState);
            const returnUrl = $this.route.snapshot.queryParamMap.get('returnUrl');
            await this.storeService.fillMarketplaceStores();
            if (returnUrl) {
              $this.router.navigate([returnUrl], { replaceUrl: true });
            } else {
              if ((userState.role !='FULLFILLMENT' && (url == 'fulfillment/login' || this.fulfillmentLogin)) || ((userState.role =='FULLFILLMENT' && url == 'login') && !this.fulfillmentLogin)) {
                $this.localLogout();
              } else {
                $this.navigateToHome();
              }
            }
          });

        const urlShop = localStorage.getItem('shop');
        if (urlShop) {
          localStorage.removeItem('shop');
          this.document.location.href = `${this.document.location.origin}/tiendas/integration/SPF?shop=${urlShop}`;
        }
      })
    );
  }

  logout(): Observable<void> {
    return this.apiService.post('Users/logout')
      .pipe(
        map(() => { return; }),
        finalize(() => this.localLogout()),
        catchError(() => of<void>())
      );
  }

  localLogout() {
    this.fullfillment = localStorage.getItem('fullfillment') == '1' ? true : false;
    localStorage.removeItem('accessToken');
    this.cookieService.delete('accessToken');
    this.cachedAccessToken = null;
    let socialLogin = localStorage.getItem('socialLogin');
    if (socialLogin && (socialLogin == 'Google' || socialLogin == 'Facebook')) {
      this.socialAuthService.signOut();
    }
    // this.updateUserState(new LoggedUserState());
    if (this.fullfillment) {
      if (this.fulfillmentLogin) {
        this.router.navigate(['login']);
      } else {
        this.router.navigate(['fulfillment/login']);
      }
    } else {
      this.router.navigate(['login']);
    }
    localStorage.removeItem('socialLogin');
  }

  getAccessToken(): string {
    return this.cachedAccessToken ? this.cachedAccessToken.token : null;
  }

  loginByToken(authToken: string, emailLogin: boolean) {
    let data = {
      EmailLogin: emailLogin,
      AuthToken: authToken,
    };
    return this.apiService.get('Users/loginByToken', data);
  }

  loginByTokenExternalSites(authToken: string): Observable<any> {
    return this.apiService.get<any>('Users/loginByTokenExternalSites', {AuthToken: authToken});
  }

  signInSocialNetwork(email: string, userName: string, socialNetwork: string, isFullfillment) {
    const socialData = {
      socialNetwork,
      email,
      isFullfillment,
      name: userName
    };
    return this.apiService.post('Users/signInSocial', socialData);
  }

  setTokenLogin(Token, path = null) {
    const $this = this;
    const routeToken = Token && Token.path ? Token.path: null
    const tokenInformation = Object.assign(new AccessTokenInfo(), Token, { user: Token.email });
    localStorage.setItem('accessToken', JSON.stringify(tokenInformation));
    $this.cookieService.set('accessToken', JSON.stringify(tokenInformation));
    $this.cachedAccessToken = Token;

    const userState = Object.assign(
      new LoggedUserState(),
      {
        user: Token.email,
        balance: null,
        paymentMode: null,
        balanceIsOutdated: false,
        authenticated: true,
        role: $this.cachedAccessToken.roles[0],
        resellerGlobalConfig: Token.ResellerGlobalConfig
      });

      if (userState && userState.resellerGlobalConfig) {
        $this.updateUserState(userState);
      }


      this.comunService.getMyConfig().subscribe(config => {
        if (config) {
          userState.resellerGlobalConfig = config;
        }
      }, err => {},
       async () => {
        $this.updateUserState(userState);
        const returnUrl = path || $this.route.snapshot.queryParamMap.get('returnUrl') || routeToken || '/dashboard';
        await this.storeService.fillMarketplaceStores();
        if (returnUrl) {
          $this.router.navigate([returnUrl], { replaceUrl: true });
        } else {
          $this.navigateToHome();
        }
      });

  }

  getTokenInfo(): AccessTokenInfo {
    return this.cachedAccessToken ? this.cachedAccessToken : null;
  }

  updateTokenInfo(DontShowAgain) {
      this.cachedAccessToken.ResellerGlobalConfig.DontShowAgain = DontShowAgain;
      let local = localStorage.getItem('accessToken');
      local = JSON.parse(local);
      local["ResellerGlobalConfig"]["DontShowAgain"] =DontShowAgain;
      localStorage.setItem('accessToken', JSON.stringify(local));
   }

   updateTokenInfoOnboarding(DontShowAgainOnboarding) {
    this.cachedAccessToken.ResellerGlobalConfig.DontShowAgainOnboarding = DontShowAgainOnboarding;
    let local = localStorage.getItem('accessToken');
    let jsonParse = this.formatService.getValidJSON(local)

    if(jsonParse && jsonParse.body && jsonParse.isValidJson){
      local = jsonParse.body;
      local["ResellerGlobalConfig"]["DontShowAgainOnboarding"] =DontShowAgainOnboarding;
      localStorage.setItem('accessToken', JSON.stringify(local));
    }
 }

  refreshResellerBalance(delayDuration?: number, setOutdatedBalanceIndicator?: boolean) {
    if (setOutdatedBalanceIndicator) {
      const userState = Object.assign(new LoggedUserState(),
        this.userSubject.getValue(),
        { balanceIsOutdated: true });

      this.userSubject.next(userState);
    }

    const seq = timer(delayDuration ? delayDuration : 0)
      .pipe(
        mergeMap(() => this.getResellerBalance().pipe(retry(1)))
      );

    seq.subscribe(
      res => {
        const userState = Object.assign(new LoggedUserState(),
          this.userSubject.getValue(),
          {
            balance: res.Balance,
            paymentMode: res.PaymentMode,
            balanceIsOutdated: false
          });

        this.userSubject.next(userState);
      }
    );

    return seq;
  }

  public updateUserState(userState: LoggedUserState) {
    this.userSubject.next(userState);
    if (userState.isReseller || userState.isFullfillment){
      this.refreshResellerBalance(500);
    }
  }

  public navigateToHome() {
    this.router.navigate(['/'], { replaceUrl: true });
  }

  private onStorageChanged = (event: StorageEvent) => {
    if (event.storageArea === localStorage && (event.key === 'accessToken' || event.key === null)) {
      const storedToken = <AccessTokenInfo>JSON.parse(localStorage.getItem('accessToken'));

      if (storedToken && storedToken.token && storedToken.roles && storedToken.roles[0]
        && storedToken.user
      ) {
        // if the user or role changed.
        const userChanged = !this.cachedAccessToken
          || this.cachedAccessToken.user !== storedToken.user
          || this.cachedAccessToken.roles[0] === storedToken.roles[0];

        this.cachedAccessToken = storedToken;

        if (userChanged) {
          const userState = Object.assign(
            new LoggedUserState(),
            {
              user: storedToken.user,
              balance: null,
              paymentMode: null,
              balanceIsOutdated: false,
              authenticated: true,
              role: this.cachedAccessToken.roles[0]
            });

          this.updateUserState(userState);
          this.navigateToHome();
        }
      } else {
        this.localLogout();
      }
    }
  }

  private setInitialUserStateFromStorage() {
    let storedToken = <AccessTokenInfo>JSON.parse(localStorage.getItem('accessToken'));
    if (!storedToken) {
      const cookiejosn = this.cookieService.get('accessToken');
      if (cookiejosn) {
        storedToken = <AccessTokenInfo>JSON.parse(cookiejosn);
      }
      if (storedToken) {
        localStorage.setItem('accessToken', JSON.stringify(storedToken));
      }
    }

    if (storedToken) {
      if (!(storedToken.token && storedToken.roles && storedToken.roles[0] && this.isTokenTtlValid(storedToken))) {
        localStorage.removeItem('accessToken');
        this.cookieService.delete('accessToken');
        return;
      }

      this.cachedAccessToken = storedToken;
      const userState = Object.assign(
        new LoggedUserState(),
        {
          user: storedToken.user,
          balance: null,
          paymentMode: null,
          balanceIsOutdated: false,
          authenticated: true,
          role: this.cachedAccessToken.roles[0],
          resellerGlobalConfig: this.cachedAccessToken.ResellerGlobalConfig
        });

      this.updateUserState(userState);
    }
  }

  public updateShipmentGeneration(state: boolean) {
    this.refreshShipmentGeneration(state ? 1  : 0);
  }

  private refreshShipmentGeneration(state: number) {
    let storedToken = <AccessTokenInfo>JSON.parse(localStorage.getItem('accessToken'));
    if (storedToken) {
      let cachedAccessToken = storedToken;
      cachedAccessToken.ResellerGlobalConfig.ShipmentGeneration = state;
      localStorage.setItem('accessToken', JSON.stringify(cachedAccessToken));

      let userState = this.userSubject.getValue();
      userState.resellerGlobalConfig.ShipmentGeneration = state;
      this.updateUserState(userState);
    }
  }

  public getResellerBalance(): Observable<ResellerBalance> {
    return this.apiService.get<ResellerBalance>('Reseller/balance');
  }
  private isTokenTtlValid(token: AccessTokenInfo): boolean {
    const createdDate = moment(token.created);
    const currentDate = moment();
    const duration = Math.abs(moment.duration(createdDate.diff(currentDate)).asSeconds());
    return duration < token.ttl;
  }

  /*Validar la caducidad del token generado por loopback */
  isAPIKeyValid(step): boolean {

    const apiKey = <AccessTokenInfo>JSON.parse(localStorage.getItem('accessToken'));
    let apiKeyflag = false;
    if (apiKey) {
      const createdDate = moment(apiKey.created);
      const currentDate = moment();
      const duration = Math.abs(moment.duration(createdDate.diff(currentDate)).asSeconds());
      apiKeyflag = duration < apiKey.ttl;
    }
    this.setWorkingSesion(apiKeyflag);
    return apiKeyflag;
  }
}
