import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, mergeMap, delay } from 'rxjs/operators';
import { parse as json2csv } from 'json2csv';
import { saveAs } from 'file-saver';
import { AuthenticationService } from '../authentication.service';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

import {
  ShipmentResume,
  Reseller,
  ResellerServiceConfig,
  ResellerApiKey,
  ResellerAddress,
  ResellerBillingInfo,
  ResellerNotificationConfig,
  ResellerRechargeConfig,
  AccountMovement, AccountMovementFF, Payment,
  PaymentOrder,
  ResellerPaymentConfig,
  ResellerCard,
  ShipmentItem,
  ResellerGeneralInfo,
  PagedResult,
  Balance,
  CargosPendientes,
  PickupResume,
  BatchResume,
  Orden,
  Courier,
  ResellerStore,
  OrdenMessage,
  resellerGlobalConfig,
  ResellerParcel,
  BatchDetail,
  PaymentOrderEtomin,
  OrdenHistory,
  OrderMapping,
  DashboardFulfillment,
  PaymentOrderPayPal, ResellerAddressResponse
} from 'src/app/core/models/models';
import { TrackingStatusPipe, RefundStatusPipe } from 'src/app/shared/pipes';
import { OpenPayService } from 'src/app/core/services/openpay.service';
import { EtominService } from 'src/app/core/services/etomin.service';
import { LogUtilService } from '../util/log.service';
import { FileUtilService } from '../util/file-util.service';
import { Store } from 'src/app/views-reseller/store/model/store';
import { ResellersComponent } from 'src/app/views-helpdesk/resellers/resellers.component';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';
import {environment} from '../../../../environments/environment';
import { OrderDetailShipment } from '../../models/order.model';
import { ResellerItem } from 'src/app/core/models/models';
import { filter } from 'jszip';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResellerService {

  shipmentTranslate: any;

  constructor(
    private apiService: ApiService,
    private commonSvc: CommonService,
    private authSvc: AuthenticationService,
    private trackingStatusPipe: TrackingStatusPipe,
    private refundStatusPipe: RefundStatusPipe,
    private openPaySvc: OpenPayService,
    private etominSvc: EtominService,
    private logHelper: LogUtilService,
    private fileUtil: FileUtilService,
    private translateServ: TranslateService
  ) {

    this.translateServ.get(['shipments']).subscribe(result => {
      this.shipmentTranslate = result.shipments;
    });

   }

  currentBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  countryCode = environment.countryCode;

  resellerInfo: Reseller;

  saveReseller(reseller: ResellerGeneralInfo) {
    return this.apiService.patch('Reseller', reseller);
  }

  getAuthReseller(refresh = true): Observable<Reseller> {
    if (this.resellerInfo && this.resellerInfo.ResellerId && !refresh) {
      return of(this.resellerInfo);
    }
    return this.apiService.get<Reseller>('Reseller').pipe(
      map(reseller => {
        this.resellerInfo = reseller;
        return reseller;
    }));
  }

  saveResellerGlobalConfig(resellerGlobalConfig: resellerGlobalConfig) {
    return this.apiService.patch('Reseller/ResellerGlobalConfig', resellerGlobalConfig);
  }

  getResellerPaymentConfig(): Observable<ResellerPaymentConfig> {
    return this.apiService.get<ResellerPaymentConfig>('Reseller/paymentConfig');
  }

  getBalance(): Observable<Balance> {
    return this.apiService.get<Balance>('Reseller/balanceInformation').pipe(tap((response) => this.setBalanceObs(response.CurrentBalance)));
  }

  getPendingCharges(): Observable<CargosPendientes[]> {
    let result =  this.apiService.get<CargosPendientes[]>('Reseller/pendingCharges');

    return result;
  }

  filterOrders(filter): Observable<Orden[]> {
    const result =  this.apiService.get<Orden[]>('Orders/filterOrders', filter);
    return result;
  }

  deleteOrder(order: Orden): Observable<string> {
    return this.apiService.delete<string>(`Orders/${encodeURIComponent(order.OrderId)}`);
  }
  updateOrderStatus(orderIds:any): Observable<Orden[]> {
    let result =  this.apiService.post<Orden[]>('Orders/updateStatus', orderIds);
    return result;
  }
  customShipment(customShipment:any): Observable<any> {
    let result =  this.apiService.post<any>('Orders/createOrderCustomShipment', customShipment);
    return result;
  }
  createCardTokenEtomin(oParams: any): Observable<any> {
    const params = {
      cardholder:oParams.holder_name,
      number:oParams.card_number,
      cvv:oParams.cvv2,
      expirationDate:oParams.expiration_month+"/"+oParams.expiration_year
    }
    return this.apiService.post('Reseller/cards/newtoken', params);
  }
  refreshOrder(): Observable<any> {
    let result =  this.apiService.get<any>('Orders/refresh');
    return result;
  }
  hasUpdateOrderPending(): Observable<any> {
    return this.apiService.get<any>('Orders/hasUpdateOrder');
  }
  getOrderDetailHistory(data): Observable<any> {
    let result =  this.apiService.post<any>('Orders/getOrderDetailHistory', data);
    return result;
  }
  getOrderHistory(): Observable<any> {
    let result =  this.apiService.get<any>('Orders/getOrderHistory');
    return result;
  }
  getStores(): Observable<Store[]> {
    let result =  this.apiService.get<Store[]>('Stores/');
    return result;
  }
  getResellerStoreByResellerId(): Observable<any> {
    let result =  this.apiService.get<Store[]>('Stores/getResellerStoreByResellerId');
    return result;
  }
  getStoresReseller(store:Store): Observable<ResellerStore[]> {
    let result =  this.apiService.get<ResellerStore[]>('Stores/storesReseller/' + '{ "StoreId":"' + store.StoreId + '" }');
    return result;
  }
  getStoresByResellerId(resellerId): Observable<any> {
    let result = this.apiService.get('Stores/StoresByResellerId/' + resellerId);
    return result;
  }
  saveStoreConfig(resellerStore:ResellerStore): Observable<ResellerStore> {
    let result =  this.apiService.post<ResellerStore>('Stores/saveStoreConfig/', resellerStore);
    return result;
  }
  disconnectStoreConfig(resellerStore:ResellerStore): Observable<ResellerStore> {
    let result =  this.apiService.post<ResellerStore>('Stores/disconnectStore/', resellerStore);
    return result;
  }

  getDetalleOrdenCompra(orderId : string): Observable<Orden> {
     let result =  this.apiService.get<Orden>('Orders/detailInfo/'+orderId);
     return result;
  }
  getHistorialOrdenCompra(orderId : string): Observable<OrdenHistory[]> {
    let result =  this.apiService.get<OrdenHistory[]>('Orders/orderHistory/'+orderId);
    return result;
 }
  cancelOrder(data) {
    return this.apiService.post<any>('Orders/cancelOrder/', data);
  }
  postOrder(OrderRequest:any): Observable<any[]> {
    let result =  this.apiService.post<any[]>('Orders/', OrderRequest);
    return result;
  }

  putOrder(OrderRequest: any): Observable<any[]> {
    const result =  this.apiService.put<any[]>('Orders/', OrderRequest);
    return result;
  }
  /**
   * Obtener el formato para carga masiva de ordenes
   */
getTemplateBulkCollectOrder(key: string) {
    return this.apiService.get<any>('Orders/template/' + key + '/bulkcollect')
  }

  // Billing Info
  getResellerBillingInfo(): Observable<ResellerBillingInfo> {
    return this.apiService.get<ResellerBillingInfo>('Reseller/billingInfo');
  }

  saveResellerBillingInfo(billingInfo: ResellerBillingInfo): Observable<ResellerBillingInfo> {
    if (billingInfo.BillingInfoId) {
      return this.apiService.patch<ResellerBillingInfo>('Reseller/billingInfo', billingInfo);
    } else {
      return this.apiService.post<ResellerBillingInfo>('Reseller/billingInfo', billingInfo);
    }
  }

  deleteResellerBillingInfo(reseller:Reseller):Observable<any>{
    return this.apiService.delete('Reseller/billingInfo/' + reseller.ResellerId);
  }

  // Reseller Service Configs

  getResellerServiceConfigs(): Observable<ResellerServiceConfig[]> {
    return this.apiService.get<ResellerServiceConfig[]>('Reseller/ServiceConfigs');
  }

  postResellerServiceConfig(serviceConfig: ResellerServiceConfig): Observable<any> {
    return this.apiService.post('Reseller/serviceConfigs', serviceConfig);
  }

  patchResellerServiceConfig(serviceConfig: ResellerServiceConfig): Observable<any> {
    return this.apiService.patch('Reseller/serviceConfigs/' + serviceConfig.ResellerServiceConfigId, serviceConfig);
  }

  deleteResellerServiceConfig(body: ResellerServiceConfig): Observable<any> {
    return this.apiService.delete('Reseller/serviceConfigs/' + body.ResellerServiceConfigId);
  }

  // Addresses
  getResellerAddresses(): Observable<ResellerAddressResponse> {
    return this.apiService.get<ResellerAddressResponse>('Reseller/addresses');
  }

  getResellerParcel(): Observable<ResellerParcel[]> {
    return this.apiService.get<ResellerParcel[]>('Parcel/');
  }

  getResellerParcelById(parcelId): Observable<ResellerParcel> {
    return this.apiService.get<ResellerParcel>('Parcel/'+parcelId);
  }

  saveResellerAddress(address: ResellerAddress): Observable<any> {
    if (address.AddressId) {
      return this.apiService.patch('Reseller/Addresses/' + address.AddressId, address);
    } else {
      return this.apiService.post('Reseller/Addresses', address);
    }
  }
  updateAddress(address: ResellerAddress): Observable<any> {
   return this.apiService.post('Orders/updateAddresses', address);
  }

  saveAddressStore(oAddressReseller: ResellerAddress,iResellerStoreId: string): Observable<any> {
    return this.apiService.post('Stores/Addresses/' + iResellerStoreId, oAddressReseller);
  }

  saveResellerParcel(parcel: ResellerParcel): Observable<any> {
    if (parcel.ParcelId) {
      return this.apiService.patch('Parcel/' + parcel.ParcelId, parcel);
    } else {
      return this.apiService.post('Parcel/', parcel);
    }
  }

  deleteResellerAddress(body: ResellerAddress): Observable<any> {
    return this.apiService.delete('Reseller/addresses/' + body.AddressId);
  }

  deleteResellerParcel(body: ResellerParcel): Observable<any> {
    return this.apiService.delete('Parcel/' + body.ParcelId);
  }

  // Api Keys
  getResellerApiKey(): Observable<ResellerApiKey[]> {
    return this.apiService.get<ResellerApiKey[]>('Reseller/applications');
  }

  resetResellerApiKey(): Observable<any> {
    return this.apiService.patch('Reseller/applications/resetKeys');
  }

  // Notification Configs
  getResellerNotificationConfigs(): Observable<ResellerNotificationConfig[]> {
    return this.apiService.get<ResellerNotificationConfig[]>('Reseller/notificationConfigs');
  }

  saveResellerNotificationConfigs(notificationConfig: ResellerNotificationConfig[]): Observable<any> {
    return this.apiService.post('Reseller/notificationConfigs/bulk', notificationConfig);
  }

  // Statement Account
  getAccountStatement(filterData: any): Observable<AccountMovement[]> {
    return this.apiService.post<AccountMovement[]>('Reseller/accountStatement/', filterData);
  }

  // Statement Account
  getAccountStatementFF(filterData: any): Observable<AccountMovementFF[]> {
    return this.apiService.post<AccountMovementFF[]>('Reseller/accountStatementFF/', filterData);
  }

  // Payments
  getAccountPayments(year: number, month: number): Observable<Payment[]> {
    return this.apiService.get<Payment[]>('Reseller/credits/' + year + '/' + month)
      .pipe(map((res) => res ? res.sort((a, b) => a.PaymentDate > b.PaymentDate ? -1 : 1) : []));
  }

  sendPaymentOrderEtomin(paymentOrder: PaymentOrderEtomin): Observable<any> {
    let url = '';
    if (this.countryCode === 'MX' && paymentOrder.paymentType !== 'OXXO') {
      url = 'Reseller/rechargeWalletEtominSPEI';
    } else if (paymentOrder.paymentType === 'OXXO') {
      url = 'Reseller/rechargeWalletEtominCashInCommerce';
    } else {
      url = 'Reseller/rechargeWalletEtomin';
    }

    return this.apiService.post(url, paymentOrder)
      .pipe(tap(() => {
        if (paymentOrder.paymentType === 'card') {
          this.authSvc.refreshResellerBalance(30000, true);
        }
      }));
  }

  sendPaymentOrder(paymentOrder: PaymentOrder): Observable<any> {
    const payment = Object.assign({ deviceSessionId: this.openPaySvc.deviceSessionId }, paymentOrder);
    const url = 'Reseller/rechargeWallet';

    this.logHelper.info("Payment",null,payment,"Request recharge");

    return this.apiService.post(url, payment)
      .pipe(tap(() => {
        if (paymentOrder.paymentType === 'card') {
          this.authSvc.refreshResellerBalance(30000, true);
        }
      }));
  }

  sendPaymentDomiciliation(paymentOrder: PaymentOrder): Observable<any> {
    const payment = Object.assign({ deviceSessionId: this.openPaySvc.deviceSessionId }, paymentOrder);
    const url = 'Reseller/rechargeWallet';

    this.logHelper.info('Payment',null, payment,'Request recharge');

    return this.apiService.post(url, payment)
      .pipe(tap(() => {
        if (paymentOrder.paymentType === 'card') {
          this.authSvc.refreshResellerBalance(30000, true);
        }
      }));
  }

  confirmTransaction3DPaymenmt(secure3DId: string): Observable<any> {
    const url = 'Reseller/confirmTransaction3DPaymenmt/';

    this.logHelper.info("Payment_3D_secure",null,{ secure3DId} ,"Request confirm charge");

    return this.apiService.put(url + secure3DId)
      .pipe(tap(() => {
          this.authSvc.refreshResellerBalance(30000, true);
      }));
  }

  // cards
  saveCardByToken(tokenId: string, cardData: any): Observable<any> {
    const card = {
      tokenId: tokenId,
      deviceSessionId: this.openPaySvc.deviceSessionId,
      cardBin: cardData.cardBin,
      cardTermination: cardData.cardTermination
    };

    return this.apiService.post('Reseller/cards/token', card);
  }

  // cards
  saveCardByTokenEtomin(tokenId: string, deviceSessionId:string, name:string, addressId: string, cardData: any): Observable<any> {
    const card = {
      tokenId: tokenId,
      deviceSessionId: deviceSessionId,
      name: name,
      addressId,
      cardBin: cardData.cardBin,
      cardTermination: cardData.cardTermination
    };

    return this.apiService.post('Reseller/cards/token/etomin', card);
  }

  getResellerCards(cardPaymentGateway): Observable<ResellerCard[]> {
    const filter = {service: cardPaymentGateway}
    return this.apiService.get<ResellerCard[]>('Reseller/cards', filter)
      .pipe(map(res => res ? res : []));
  }

  // Recharge Configuration
  getResellerRechargeConfig(): Observable<ResellerRechargeConfig> {
    return this.apiService.get<ResellerRechargeConfig>('Reseller/rechargeConfig');
  }

  saveResellerRechargeConfig(config: ResellerRechargeConfig): Observable<any> {
    if (config.ResellerRechargeConfigId) {
      return this.apiService.patch('Reseller/rechargeConfig/' + config.ResellerRechargeConfigId, config);
    } else {
      return this.apiService.post('Reseller/rechargeConfig', config);
    }
  }

  getResellerAddressPickup(): Observable<any> {
    return this.apiService.get<any>('Reseller/pickup/address').pipe(
      map(resultados => {
        return {
          resultados:(resultados.message)?[]:resultados.addresses.sort((a, b) => a.state < b.state ? -1 : 1)
          ,clientId: resultados._id
          ,totalItems:resultados.length
        };
      })
    );
  }

  postPickup(pickup: any): Observable<any> {
    return this.apiService.post<any>('Reseller/pickup/new', pickup);
  }

  getResellerBatchByFilter(filteredOpen:boolean): Observable<any> {
    let filter = {IsOpen:(filteredOpen)?1:0}
    return this.apiService.get<any>('Batches/',filter);
  }

  getBatchDetail(batchId:string): Observable<BatchDetail> {
    let filter = { batchId:batchId}
    return this.apiService.get<BatchDetail>('Batches/getDetail/',filter);
  }

  downloadShipmentBatch(batch: any) {
    return this.apiService.get<any>('Batches/printShipment',{ batchId: batch.batchId})
      .pipe(
        mergeMap( shipmentPDF  =>
            this.fileUtil.SaveBase64PDFNewFile(batch.fileName + '.pdf', shipmentPDF)
        )
      );
  }
  downloadPickList(batch: any) {
    return this.apiService.get<any>('Batches/printPickList',{ batchId: batch.batchId})
      .pipe(
        mergeMap( pickListPDF  =>
          this.fileUtil.SaveBase64PDFNewFile(batch.fileName + '.pdf', pickListPDF)
        )
      );
  }

  getResellerPickupByFilter(): Observable<any> {
    return this.apiService.get<any>('Reseller/pickup/byFilter').pipe(
      map(resultados => {
        return {

          resultados:(!resultados) ? false : resultados.map(i => {
            i.pickup_date = new Date(i.pickup_date);
            i.created_date = new Date(i.created_date);

            return Object.assign(new PickupResume(), i);
          }).sort((a, b) => a.created_date < b.created_date ? -1 : 1)
          ,totalItems:resultados.length
        };
      })
    );
  }

  getResellerShipmentsByFilter(filter): Observable<PagedResult<ShipmentResume>> {
    return this.apiService.get<any>('Reseller/shipments/countByFilter', filter)
      .pipe(
        mergeMap(countRes => {
          const records$ = countRes.counter > 0
            ? this.apiService.get<ShipmentResume[]>('Reseller/shipments/byFilter', filter)
            : of([]);

          return records$.pipe(
            map(records => {
              return {
                records: records.map(i => {
                  i.CreatedAt = new Date(i.CreatedAt);
                  i.ExpiresAt = new Date(i.ExpiresAt);

                  return Object.assign(new ShipmentResume(), i);
                }),
                totalItems: countRes.counter
              };
            })
          );
        })
      );
  }

  getShipmentsByPeriod(month: number, year: number): Observable<ShipmentItem[]> {
    return this.apiService.get<ShipmentItem[]>('Reseller/shipments/byPeriod', { month: month, year: year });
  }

  downloadShipmentsByPeriod(month: number, year: number): Observable<{ recordCount: number }> {
    const fields = [
      { label: 'Fecha de creación', value: (row) => new Date(row.CreatedAt).toLocaleDateString() },
      { label: 'Paquetería', value: 'CourierName' },
      { label: 'Paquetería Cod.', value: 'CourierCode' },
      { label: 'Servicio', value: 'CourierService' },
      { label: 'Referencia', value: 'ResellerReference' },
      { label: `No. de ${this.shipmentTranslate.statusShipmentTitle}`, value: 'TrackingNumber' },
      { label: 'Rastreo', value: (row) => this.trackingStatusPipe.transform(row.TrackingStatus) },

      { label: 'Remitente Nombre', value: 'Sender.Name' },
      { label: 'Remitente Compañia', value: 'Sender.CompanyName' },
      { label: 'Remitente Tel. 1', value: 'Sender.Phone1' },
      { label: 'Remitente Tel. 2', value: 'Sender.Phone2' },
      { label: 'Remitente Email', value: 'Sender.Email' },

      { label: 'Remitente C.P', value: 'AddressFrom.ZipCode' },
      { label: 'Remitente Estado Cod.', value: 'AddressFrom.State' },
      { label: 'Remitente Estado', value: (row) => this.commonSvc.getStateName(row.AddressFrom.State) },
      { label: 'Remitente Ciudad', value: 'AddressFrom.City' },
      { label: 'Remitente Colonia', value: 'AddressFrom.Neighborhood' },
      { label: 'Remitente Calle y Núm.', value: 'AddressFrom.Address1' },
      { label: 'Remitente Referencias', value: 'AddressFrom.Address2' },

      { label: 'Destinatario C.P', value: 'AddressTo.ZipCode' },
      { label: 'Destinatario Estado Cod.', value: 'AddressTo.State' },
      { label: 'Destinatario Estado', value: (row) => this.commonSvc.getStateName(row.AddressTo.State) },
      { label: 'Destinatario Ciudad', value: 'AddressTo.City' },
      { label: 'Destinatario Colonia', value: 'AddressTo.Neighborhood' },
      { label: 'Destinatario Calle y Núm.', value: 'AddressTo.Address1' },
      { label: 'Destinatario Referencias', value: 'AddressTo.Address2' },

      { label: 'Destinatario Nombre', value: 'Recipient.Name' },
      { label: 'Destinatario Compañia', value: 'Recipient.CompanyName' },
      { label: 'Destinatario Tel. 1', value: 'Recipient.Phone1' },
      { label: 'Destinatario Tel. 2', value: 'Recipient.Phone2' },
      { label: 'Destinatario Email', value: 'Recipient.Email' },

      { label: 'Peso Cotizado (kg)', value: 'QuotedWeight' },
      { label: 'Peso Amparado (kg)', value: 'CoveredWeight' },
      { label: 'Sobrepeso (kg)', value: 'OverWeight' },
      { label: 'Peso real (kg)', value: 'RealWeight' },
      { label: 'Alto del Paquete (cm)', value: 'Parcel.Height' },
      { label: 'Ancho del Paquete (cm)', value: 'Parcel.Width' },
      { label: 'Largo del Paquete (cm)', value: 'Parcel.Length' },
      { label: 'Monto Asegurado (MXN)', value: 'InsuredAmount' },

      { label: 'Precio Cotizado (MXN)', value: 'QuotedAmount' },
      { label: 'Precio Base (MXN)', value: 'CoveredAmount' },
      { label: 'Sobrepeso Cotizado (MXN)', value: (row) => (row.ExtrasAmount - row.OverWeightCounterAmount).toString() },
      { label: 'Seguro (MXN)', value: 'InsuranceAmount' },
      { label: 'Descuento (MXN)', value: 'DiscountAmount' },
      { label: 'Cargo Extra (MXN)', value: 'OverWeightCounterAmount' },
      { label: 'Precio final (MXN)', value: 'TotalAmount' },
      { label: 'Estado del Reembolso', value: (row) => this.refundStatusPipe.transform(row.Status) },
      { label: 'Fecha de Entrega', value: (row) => new Date(row.ReceivedAt).toLocaleDateString() },
      { label: 'Recibido Por', value: 'ReceivedBy' },
    ];

    return this.getShipmentsByPeriod(month, year).pipe(
      map(shipments => {

        if (!shipments || shipments.length < 1) {
          return {
            recordCount: 0
          };
        }

        const csv = json2csv(shipments, { fields: fields });
        const csvName = 'Envios_' + year + '_' + month + '.csv';
        try {
          saveAs(new Blob(['\ufeff' + csv]), csvName);
        } catch (err) {
          console.log(err);
          throw new Error('No fue posible descargar el archivo');
        }

        return {
          recordCount: shipments.length
        };
      })
    );
  }

  updatePhoneNumber(phoneNumber: string) {
    return this.apiService.post('Reseller/phone', phoneNumber);
  }

  sendPhoneNumberVerificationCode(phoneNumber: string) {
    return this.apiService.post('Reseller/phone', phoneNumber);
  }
  sendEmailVerificationCode() {
    return this.apiService.post('Reseller/email');
  }

  validateDuplicatedPhone(phoneNumber: string, resellerId: string): Observable<any>{
    return this.apiService.post('Reseller/sendPhoneVerificationCode', {
      phoneNumber,
      resellerId
    });
  }

  validateDuplicatedEmail(email: string, resellerId: string): Observable<any>{
    return this.apiService.post('Reseller/sendEmailVerificationCode', {
      email,
      resellerId
    });
  }

  verifyPhoneCode(payload: any): Observable<any>{
    return this.apiService.post('Reseller/verifyPhone', payload);
  }

  verifyEmailCode(payload: any): Observable<any>{
    return this.apiService.post('Reseller/verifyEmail', payload);
  }

  verifyPhoneNumber(verificationCode: string) {
    return this.apiService.post('Reseller/phone/confirm', { Token: verificationCode.toUpperCase() });
  }
  verifyEmail(verificationCode: string) {
    return this.apiService.post('Reseller/email/confirm', { Token: verificationCode.toUpperCase() });
  }

  getCourierConfig(): Observable<any[]> {
    return this.apiService.get<any[]>('Reseller/ConfigCourier');
  }

  setConfCourier(courierCode: string, courierName: string) {
    return this.apiService.post(`Reseller/setConfigCourier/${courierCode}/${courierName}`);
  }

  getResellerStoreById(id: string): Observable<ResellerStore> {
    return this.apiService.get<ResellerStore>('Stores/ResellerStore/' + id);
  }

  getResellerStoreByShop(url: string): Observable<ResellerStore> {
    return this.apiService.get<ResellerStore>('Stores/ResellerStore/url', {shop: url});
  }

  getRetrieveCourierService() {
    return this.apiService.get('Reseller/getRetrieveCourierService');
  }

  getCourierAccountByReseller() {
    return this.apiService.get('Reseller/getCourierAccountByReseller');
  }

  evaluateDefinitionOrder(resellerStoreId, orderList: OrderMapping[] ): Observable<any> {
    let result =  this.apiService.post<any>('Orders/validateDefinition/'+resellerStoreId, orderList);
    return result;
 }

 validateEmailByLink(data): Observable<any> {
  let result =  this.apiService.post<any>('Reseller/email/confirmLink', data);
  return result;
}

saveResellerCourierServices(data): Observable<any> {
  let result =  this.apiService.post<any>('Reseller/saveResellerCourierServices', data);
  return result;
}

editResellerCourierService(data): Observable<any> {
  let result =  this.apiService.post<any>('Reseller/editResellerCourierService', data);
  return result;
}

activeResellerCourierService(data): Observable<any> {
  let result =  this.apiService.post<any>('Reseller/activeResellerCourierService', data);
  return result;
}
sendOrderMiddleware(): Observable<any> {
  return this.apiService.post<any>('Orders/sendOrderMiddleware');
}

geDashboardFulfillment(StartDate: any,EndDate:any): Observable<DashboardFulfillment> {
  const param={
    startDate :StartDate,
    endDate : EndDate,
  }
  let result =  this.apiService.get<DashboardFulfillment>('Orders/dashboardFulfillment',param);
  return result;
}

saveRecurringChargeConfig(data): Observable<any> {
  return this.apiService.post<any>('Reseller/saveRecurringChargeConfig', data);
}

getRecurringChargeConfig(): Observable<any> {
  return this.apiService.get<any>('Reseller/getRecurringChargeConfig');
}

updateRecurringChargeConfig(data): Observable<any> {
  return this.apiService.put<any>('Reseller/updateRecurringChargeConfig', data);
}

  getRateInformationConfig(): Observable<any> {
    return this.apiService.get<any>('Reseller/RateInformation');
  }

  // Notification
  saveResellerNotification(model): Observable<any> {
    return this.apiService.post<any>('Reseller/Notification/saveResellerConfig', model);
  }

  saveClientNotification(model): Observable<any> {
    return this.apiService.post<any>('Reseller/Notification/saveClientConfig', model);
  }

  saveCustomNotification(model): Observable<any> {
    return this.apiService.post<any>('Reseller/Notification/saveCustomConfig', model);
  }

  getResellerNotifiConfig(): Observable<any> {
    return this.apiService.get<any>('Reseller/Notification/getConfig');
  }

  sendTemplateEmailCustom(type, data) {
    return this.apiService.post('Reseller/Notification/emailCustomTemplates/' + type, data);
  }

  getTemplatesEmailCustom() {
    return this.apiService.get('Reseller/Notification/emailCustomTemplates');
  }

  // saveResellerCourierServices(data): Observable<any> {
  //   let result =  this.apiService.post<any>('Reseller/saveResellerCourierServices', data);
  //   return result;
  // }

  saveResellerCourierCustom(data): Observable<any> {
    return this.apiService.put<any>('Reseller/saveResellerCourierCustom', data);
  }

  getResellerCourierTicket(ResellerCourierServiceId): Observable<any> {
    const param = {
      resellerCourierServiceId: ResellerCourierServiceId
    }
    return this.apiService.get<any>('Reseller/getResellerCourierTicket', param);
  }

  getTicketBinnacle(resellerCourierServiceId): Observable<any> {
    return this.apiService.get<any>(`Reseller/getTicketBinnacle?resellerCourierServiceId=${resellerCourierServiceId}`);
  }

  sendPaymentOrderPaypal(sendPaymentOrderPaypal: PaymentOrderPayPal): Observable<any> {
    const payment = Object.assign( sendPaymentOrderPaypal);

    const url = 'Reseller/rechargeWalletPayPal';
    return this.apiService.post<any>(url, payment)
    .pipe(tap(() => {
      if (sendPaymentOrderPaypal.statusTransaction === 'COMPLETED') {
        this.authSvc.refreshResellerBalance(30000, true);
      }
    }));

  }
  savePaymentPaypal(sendPaymentOrderPaypal: PaymentOrderPayPal): Observable<any> {
    const payment = Object.assign( sendPaymentOrderPaypal);

    const url = 'Reseller/savePaymentPaypal';
    return this.apiService.post<any>(url, payment)
    .pipe(tap(() => {
      if (sendPaymentOrderPaypal.statusTransaction === 'COMPLETED') {
        this.authSvc.refreshResellerBalance(30000, true);
      }
    }));

  }
  updatePaymentPaypal(sendPaymentOrderPaypal: PaymentOrderPayPal): Observable<any> {
    const payment = Object.assign( sendPaymentOrderPaypal);

    const url = 'Reseller/updatePaymentPaypal';
    return this.apiService.post<any>(url, payment)
    .pipe(tap(() => {
      if (sendPaymentOrderPaypal.statusTransaction === 'COMPLETED') {
        this.authSvc.refreshResellerBalance(30000, true);
      }
    }));

  }

  getResellerConfigCommercial(): Observable<any> {
    return this.apiService.get<any>('Reseller/notificationConfig/active');
  }

  async evaluateItemsData(itemList) {
    return await this.apiService.post<any>('Orders/evaluateItemsData', itemList).toPromise();
 }

 async evaluateProvidersData(itemList) {
  return await this.apiService.post<any>('Orders/evaluateProvidersData', itemList).toPromise();
}

 updateTypeServiceQuote(OrderId, TypeServiceQuote):Observable<any>{
   return this.apiService.patch<OrderDetailShipment>(`Orders/${OrderId}/typeServiceQuote`,{TypeServiceQuote});
 }

  getLoginConfigActive(fullfillmentFlag: boolean): Observable<any> {
    return this.apiService.get<any>(`Reseller/LoginConfig/active?fulfillmentFlag=${fullfillmentFlag}`);
  }

  getResellerItem(): Observable<any[]> {
    const url = 'Reseller/all-items';
    return this.apiService.get<any[]>(url);
  }

  // ResellerItem Nueva estructura de Items en Seller
  createUpdateResellerItem(item, component) {
    const params = {
      component,
      item
    };
    return this.apiService.post(`Reseller/item`, params);
  }

  saveItemsMasive(items:any): Observable<any> {
    const params = {
        dataItem:items
      }
    return this.apiService.post<any>('Reseller/saveItemsMasive', params);
  }

  createResellerStoreItem(item): Observable<any> {
    return this.apiService.post(`Reseller/createResellerStoreItem`, item);
  }

  updateItemDimensions(data): Observable<any> {
    return this.apiService.patch<any>(`Orders/updateItemDimensions`, data);
  }

  /**
   * @description Return a observable
   * @returns A Observable
   * @see PT-1456
   */
    public getBalanceObs() {
      return this.currentBalance$.asObservable();
    }
    /**
     * @param balance
     * @description Save the balance
     * @see PT-1456
     */
    public setBalanceObs(balance: number) {
      this.currentBalance$.next(balance);
    }

  /**
   * @param coupon
   * @description Set promoCode
   * @see PT-1459 
   */
  setPromoCode(code:string) {
    const params = {
      promoCode: code
    }
    const resp = this.apiService.post<any>(`Promotions/validateCode`, params);
    return resp;
  }  

    /**
   * @param coupon
   * @description Get shipments promo
   * @see PT-1459 
   */
  getShipmentsPromo() {
    return this.apiService.get<any>(`Promotions/getShipmentsPromo`);
  }

  /**
   * @description Creates reseller next id
   * @see PT-1457
   */
  createNextId(resellerId) {
    const params = {
      resellerId
    };
    return this.apiService.post<any>(`Reseller/insertUserErp`, params);
  }

}
