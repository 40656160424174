import { AbstractControl } from '@angular/forms';

const denominationNotAllowed: string[] = [
  ' S.A. de C.V.',
  ' SA de CV',
  ' S. en C. S.',
  ' S en C S',
  ' S. en C. por A.',
  ' S en C por A',
  ' S. DE R.L.',
  ' S DE R L',
  ' S. A.',
  ' S.A.',
  ' S A',
  ' S. C.',
  ' S.C.',
  ' S C',
  ' SAPI de CV',
  ' SAPI',
  ' SAB DE CV',
];

export function socialDenominationValidator(control: AbstractControl) {
  const {value = ''} = control;
  if (denominationNotAllowed.some(denomination => value.trim().toLowerCase().includes(denomination.toLowerCase()))) {
    return { invalidDenomination: true };
  }
  return null;
}
